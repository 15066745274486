import React from 'react'

export default function Footer() {
  return (
    <footer>
        <div className="footer-content">
            <div className="footer-row">
                <a href="https://www.lahlouh.com" target="_blank" rel="noreferrer" title="Lahlouh Homepage"><img className="footer-logo" src="./assets/images/Lahlouh_Logo-White.svg" alt="Footer Logo"/></a>
                <ul className="footer-nav">
                    <li><a href="https://www.lahlouh.com/about" target="_blank" rel="noreferrer" title="About Lahlouh">ABOUT</a></li>
                    <li><a href="https://www.lahlouh.com/capabilities" rel="noreferrer" target="_blank" title="Lahlouh Capabilities">CAPABILITIES</a></li>
                    <li><a href="https://www.lahlouh.com/industries" rel="noreferrer" target="_blank" title="Industries">INDUSTRIES</a></li>
                    <li><a href="https://www.lahlouh.com/brandbox" target="_blank" rel="noreferrer" title="Brandbox">BRANDBOX</a></li>
                    <li><a href="https://lahlouh.jazz.co/" target="_blank" rel="noreferrer" title="Careers">CAREERS</a></li>
                    <li><a href="https://www.lahlouh.com/blog" target="_blank" rel="noreferrer" title="Blog">BLOG</a></li>
                    <li><a href="https://www.lahlouh.com/contact" target="_blank" rel="noreferrer" title="Contact Us">CONTACT</a></li>
                </ul>
            </div>
            <div className="footer-row align-top mt20">
                <div>
                    <div className="footer-heading">
                        Headquarters
                    </div>
    
                    <div className="mt5">
                        1649 Adrian Rd<br />
                        Burlingame, California 94010
                    </div>

                    <div className="footer-heading mt20">
                        Branch
                    </div>

                    <div className="mt5">
                        150 Lawton Ave<br />
                        Monroe, Ohio 45050
                    </div>
                </div>

                <div className="social-wrapper">
                    <div className="social-container">
                        <div className="social-icon">
                            <a href="https://www.linkedin.com/company/lahlouh/" target="_blank" rel="noreferrer" title="LinkedIn">
                                <img src="./assets/images/linkedin.svg" alt="Lahlouh LinkedIn" />
                            </a>
                        </div>

                        <div className="social-icon">
                            <a href="https://www.facebook.com/LahlouhInc" target="_blank" rel="noreferrer" title="Facebook">
                                <img src="./assets/images/facebook.svg" alt="Lahlouh Facebook" />
                            </a>
                        </div>

                        <div className="social-icon">
                            <a href="https://www.instagram.com/lahlouhinc/" target="_blank" rel="noreferrer" title="Instagram">
                                <img src="./assets/images/instagram.svg" alt="Lahlouh Instagram" />
                            </a>
                        </div>

                        <div className="social-icon">
                            <a href="https://www.youtube.com/channel/UCvPeh9OIRCekjOLW63r3FEQ/featured" target="_blank" rel="noreferrer"title="YouTube">
                                <img src="./assets/images/youtube.svg" alt="Lahlouh YouTube" />
                            </a>
                        </div>
                    </div>
                    <div className="footer-copy">
                        &copy; 2023 Lahlouh All rights reserved | <a href="https://www.lahlouh.com/privacy" target="_blank" rel="noreferrer"title="Privacy Policy">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>

    </footer>
  )
}
