import React, { useState, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export default function UsersTable(props) {
    const { users } = props;
    const [sortConfig, setSortConfig] = useState({key: 'username', direction:'ascending'});
    const navigate = useNavigate();

    // DOM References
    const ref_username = useRef();
    const ref_fname = useRef();
    const ref_lname = useRef();
    const ref_role = useRef();
    const ref_status = useRef();
    const ref_company = useRef();
    
    let sortedUsers = [...users];

    sortedUsers.sort((a, b) => {
        if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const requestSort = key => {
        // Remove all existing table sorting arrows
        ref_username.current.className = '';
        ref_fname.current.className = '';
        ref_lname.current.className = '';
        ref_role.current.className = '';
        ref_status.current.className = '';
        ref_company.current.className = '';

        let direction = 'ascending';
        // Eval to allow use of dynamic variable name
        eval('ref_' + key + '.current.className="arrow-up"');

        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
            eval('ref_' + key + '.current.className="arrow-down"');
        }
        setSortConfig({ key, direction });
    }

    return (
        <table className="users-table mt15" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th onClick={() => requestSort('username')} title="Sort by Username">Username <span ref={ref_username} className="arrow-up"></span></th>
                <th onClick={() => requestSort('fname')} title="Sort by First Name">First Name <span ref={ref_fname}></span></th>
                <th onClick={() => requestSort('lname')} title="Sort by Last Name">Last Name <span ref={ref_lname}></span></th>
                <th onClick={() => requestSort('role')} title="Sort by Role">Role <span ref={ref_role}></span></th>
                <th onClick={() => requestSort('status')} title="Sort by Status">Status <span ref={ref_status}></span></th>
                <th onClick={() => requestSort('company')} title="Sort by Portal">Portal <span ref={ref_company}></span></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
        {sortedUsers.map(user => {
            return (
            <tr key={user.id} title={"Click to view/edit " + user.username} onClick={() => navigate('/user?id=' + user.id)} style={{color: user.status === 'disabled' && '#a79b72'}}>
                <td data-label="Username">{user.username}</td>
                <td data-label="First Name">{user.fname}</td>
                <td data-label="Last Name">{user.lname}</td>
                <td data-label="Role">{user.role}</td>
                <td data-label="Status">{user.status}</td>
                <td data-label="Portal">{user.customer_number.split(',').length === 1 ? user.company : user.customer_number.split(',').length + ' portals'}</td>
                <td>
                    <div className="edit-icon" title="Edit request" onClick={() => navigate('/user?id=' + user.id)}></div>
                </td>
            </tr>
            )
        })}
        
        </tbody>
        </table>
    )
}
