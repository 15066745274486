import React, { useState, useRef, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../context/UserContext';

export default function Login() {
  const [state, setState] = useState({username: '', password: '', remember_me: 'yes'});
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const refUserName = useRef();
  const refUserNameError = useRef();
  const refPassword = useRef();
  const refPasswordError = useRef();

  // Heading Modal References
  const overlay = useRef();
  const popup = useRef();
  const modalHeading = useRef();
  const modalContent = useRef();
  const modalClose = useRef();

  const handleChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  // Informational Modal
  function infoModal(heading, content, autoclose = false){
    modalHeading.current.innerHTML = heading;
    modalContent.current.innerHTML = content;
    let scrollbarWidth = window.innerWidth - document.body.clientWidth;
    document.body.style.overflow = "hidden";
    document.body.style.marginRight = scrollbarWidth + 'px';
    overlay.current.className = 'show';
    popup.current.className = 'show';
    if(autoclose){
        modalClose.current.style.display = 'none';
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.marginRight = '0';
            overlay.current.className = '';
            popup.current.className = '';
        }, 2000)
    }else{
        modalClose.current.style.display = 'block';
    }
  }

  function closePopup(){
    document.body.style.overflow = "auto";
    document.body.style.marginRight = '0';
    if(overlay.current) overlay.current.className = '';
    if(popup.current) popup.current.className = '';
  }

  // Close popup by clicking anywhere outside of it
  window.onclick = function(event) {
    if (event.target.id === 'overlay') {
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(overlay.current) overlay.current.className = '';
        if(popup.current) popup.current.className = '';
    }
}

  const handleSubmit = async e => {
    e.preventDefault();

    // Form Validation
    let shouldSubmit = true;

    refUserName.current.classList.remove('error-outline');
    refUserNameError.current.style.display='none';
    refPassword.current.classList.remove('error-outline');
    refPasswordError.current.style.display='none';

    if(state.username === ''){
      refUserName.current.classList.add('error-outline');
      refUserNameError.current.innerHTML = 'Please enter a username.';
      refUserNameError.current.style.display='block';
      shouldSubmit = false;
    }

    if(state.password === ''){
      refPassword.current.classList.add('error-outline');
      refPasswordError.current.innerHTML = 'Please enter a password.';
      refPasswordError.current.style.display='block';
      shouldSubmit = false;
    }
    // End of Form Validation
 
    if(shouldSubmit){
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({username:state.username, password:state.password})
      };

      let response = await fetch('https://test-picform.lahlouhonline.com/api/login', requestOptions)

      let data = await response.json();
    
      // Successful login
      if(data.username){
        setUser(data);

        // Set last active date
        let todaysDate = new Date()
        todaysDate.setHours(0,0,0,0);
        todaysDate = todaysDate.toISOString().split('T')[0];
        
        let requestOptions = {
          method: 'PATCH',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({last_active: todaysDate})
        };
      
        //response = await fetch('https://test-picform.lahlouhonline.com/api/users/' + data.id, requestOptions)
        response = await fetch('https://test-picform.lahlouhonline.com/api/users/' + data.id, requestOptions)
        data = await response.json()
      // Unsuccessful login
      }else{
        if(data.message.indexOf('disabled') != -1){
          refPasswordError.current.innerHTML = 'Your account is disabled. Please contact your administrator.';
        }else{
          refPasswordError.current.innerHTML = 'You have entered an invalid username and/or password.';
        }
        
        refPasswordError.current.style.display='block';
        setUser(null)
      }

      if(window.location.pathname === '/login') navigate('/');
    }
  }

  useEffect(() => {
    refUserName.current.focus();
  }, [])

  return (
    <>
    <div className="main">
        <div className="main-content">
            <h1 className="mt10">Login</h1>

            <div className="login-wrapper">
                <div className="center">
                  <img src="/assets/images/lahlouh.png" height="85" alt="Lahlouh Logo"/>
                </div>

                <form onSubmit={handleSubmit}>
                  {/* Username */}
                  <div className="element">
                      <label>Username</label>
                      <input 
                          name="username"
                          ref={refUserName}
                          type="email"
                          value={state.username}
                          onChange={handleChange}
                      />

                      <div ref={refUserNameError} className="errorMessage">Please enter a username.</div>
                  </div>

                  {/* Password */}
                  <div className="element">
                      <label>Password</label>
                      <input 
                          name="password"
                          ref={refPassword}
                          type="password"
                          value={state.password}
                          onChange={handleChange}
                      />

                      <div ref={refPasswordError} className="errorMessage">Please enter a password.</div>
                  </div>

                  <div className="mt20 element center">
                      <button type="submit" className="primary-button" style={{margin:'0 !important'}} title="Login" onClick={handleSubmit}>Login</button>
                  </div>

                </form>
            </div>

        </div>
    </div>

    <div id="overlay" ref={overlay}></div>

    <div id="popup" ref={popup}>
        <h2 id="modal-heading" ref={modalHeading} className="center">Heading</h2>
        <span id="popup-close" ref={modalClose} className="close" onClick={closePopup}>&times;</span>
        <div id="modal-content" ref={modalContent} className="content center">
          Text changed through JS
        </div>
    </div>
    </>
  )
}
