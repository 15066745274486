import React from 'react'
import { useLocation } from 'react-router-dom'

export default function ThankYou() {
  const { state } = useLocation();
  const { type } = state;

  return (
    <div className="main">
        <div className="main-content">
            {type && 
            <h1 className="mt10">Thank You</h1>
            }

            {type && type === 'group' && 

            <div className="mt20 center">
                Your group request has been successfully submitted.
                <p>
                    You can track request statuses by clicking on 'Requests' in the top navigation.
                </p> 
            </div>
            }
            
            {type && type === 'request' && 

            <div className="mt20 center">
                Your request has been submitted and/or updated.
                <p>
                    You can track request status by clicking on 'Requests' in the top navigation.
                </p> 
            </div>
            }

            {type && type === 'user' && 

            <div className="mt20 center">
                User has been successfully added.
                <p>
                    To see an updated list of users, click on 'Users' in the top navigation.
                </p> 
            </div>
            }

            {type && type === 'client' && 

            <div className="mt20 center">
                Client has been successfully added.
                <p>
                    To see an updated list of clients, click on 'Clients' in the top navigation.
                </p> 
            </div>
            }

        </div>
    </div>
  )
}
