import React from 'react'

export default function NoPage() {
  return (
    <>
    <div className="main">
        <div className="main-content center">
            <h3>You've reached a page that doesn't exist!</h3>
        </div>
    </div>
    </>
  )
}
