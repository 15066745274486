import React, { useRef, useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../context/UserContext';

export default function Home() {
  const refReturnTop = useRef();
  const [auth, setAuth] = useState(false)
  const { user, setUser } = useContext(UserContext);
  
  useEffect(() => {
    // Verify JWT Token
    const verifyUser = async () => {
      const requestOptions = {
          method: 'POST',
          credentials: 'include',
      };

      let response = await fetch('https://test-picform.lahlouhonline.com/api/verify', requestOptions);
      let data = await response.json()
      if(data.username){
        setAuth(true)
      }else{
        setUser(null)
      }
    }
    verifyUser();

  }, [])

  // Back to top scroll function
  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
    if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
        if(refReturnTop.current){
            refReturnTop.current.style.display = "block";
        }
    } else {
        if(refReturnTop.current){
            refReturnTop.current.style.display = "none";
        }
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  if(!auth) return null

  return (
    <>
    <div className="hero-banner"></div>

    <div className="main">
        <div className="main-content">
            <div className="welcome-text">Welcome, {user.fname}</div>

            <div className="button-section">
                <Link to="/add-change" className="primary-button" title="Add/Change Item">Add/Change Item</Link>
                <Link to="/remove" className="primary-button mt15" title="Add/Change Item">Remove Item</Link>
                <Link to="/update-image" className="primary-button mt15" title="Add/Change Item">Update Image</Link>
                <Link to="/requests" className="secondary-button mt15" title="Add/Change Item">View All Requests</Link>
            </div>
        </div>
    </div>

    <div ref={refReturnTop} className="return-top" onClick={topFunction}>
      &#x25B2; Return to top
    </div>
    </>
  )
}
