import React, { useState, useRef, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import RequestsTable from '../Components/RequestsTable';
import { UserContext } from '../context/UserContext';

export default function Requests() {
    const refReturnTop = useRef();
    const refSearch = useRef();
    const refClearSearch = useRef();
    const [ requests, setRequests ] = useState(null)
    const [ original, setOriginal ] = useState(null)

    const [confirmDelete, setConfirmDelete] = useState(false);
    
    const { user, setUser } = useContext(UserContext);
    const [ search, setSearch ] = useState('')
    const navigate = useNavigate();

    //Overlay References
    const overlay = useRef();
    const loadingOverlay = useRef();
    const popup = useRef();
    const modalHeading = useRef();
    const modalContent = useRef();
    const modalClose = useRef();
    
    const popupConfirm = useRef();
    const popupConfirmHeading = useRef();
    const popupConfirmContent = useRef();
    const popupConfirmClose = useRef();

    async function clearSearch(){
        setSearch('');
        refSearch.current.style.width = '225px';
        refClearSearch.current.style.display = 'none';
        setRequests(original);
    }

    // Search functionality
    async function handleChange(e){
        e.preventDefault();
        // Adjust width of search box with CSS transition
        if(e.target.value !== ''){
            refSearch.current.style.width = '250px';
            refClearSearch.current.style.display = 'inline';
        }else{
            refSearch.current.style.width = '225px';
            refClearSearch.current.style.display = 'none';
        }

        // Filter result set in real-time
        if(e.target.value === ''){
            setRequests(original);
        }else{
            const results = original.filter(item => item.name.toLowerCase().indexOf(e.target.value) !== -1 || item.id.toString().indexOf(e.target.value) !== -1)
            setRequests(results)
        }

        setSearch(e.target.value)
    }

    function showLoading(){
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        loadingOverlay.current.className = 'show';
    }
  
    function hideLoading(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        loadingOverlay.current.className = '';
    }

    useEffect(() => {
        if(confirmDelete) handleDelete()
    }, [confirmDelete]);

    // Pull in MySQL Data - Only once on page load
    useEffect(() => {
        // Verify JWT Token, and then pull data
        const verifyUser = async () => {
            const requestOptions = {
                method: 'POST',
                credentials: 'include',
            };

            let response = await fetch('https://test-picform.lahlouhonline.com/api/verify', requestOptions);
            let data = await response.json()
            if(data.username){
                const requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                };
        
                fetch('https://test-picform.lahlouhonline.com/api/requests', requestOptions)
                .then(response => response.text())
                .then(data => {
                    // String with '[]' to start, longer means there is data
                    if(data.length > 2 && data !== 'Forbidden'){
                        setRequests(JSON.parse(data));
                        setOriginal(JSON.parse(data));
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            }else{
                await setUser(null)
            }
        }

        verifyUser();
        
        hideLoading();
    }, [])

    function closePopup(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(overlay.current) overlay.current.className = '';
        if(popup.current) popup.current.className = '';
        if(popupConfirm.current) popupConfirm.current.className = '';
    }
    
    // Close popup by clicking anywhere outside of it
    window.onclick = function(event) {
        if (event.target.id === 'overlay') {
            document.body.style.overflow = "auto";
            document.body.style.marginRight = '0';
            if(overlay.current) overlay.current.className = '';
            if(popup.current) popup.current.className = '';
            if(popupConfirm.current) popupConfirm.current.className = '';
        }
    }

    // Back to top scroll function
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "block";
            }
        } else {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "none";
            }
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    async function handleDelete(){
        let count = 0;
        let elements = document.getElementsByName('pending-check');
        for(let a=0;a<elements.length;a++){
            if(elements[a].checked){
                count++;
            }
        }

        popupConfirmHeading.current.innerHTML = 'Confirm Delete';
        if(count === 1){
            popupConfirmContent.current.innerHTML = 'Are you sure you want to delete the selected request?'
        }else if(count > 1){
            popupConfirmContent.current.innerHTML = 'Are you sure you want to delete the selected requests?'
        }

        if(!confirmDelete){
            confirmOverlay();
            return;
        }else{
            const requestOptions = {
                method: 'DELETE',
                credentials: 'include',
            };
            
            let response;
            let data;
            let error = false;
            count = 0;
            let newObj = [...requests]

            for(let a=0;a<elements.length;a++){
                if(elements[a].checked){
                    let id = elements[a].id.split('-')[2]

                    response = await fetch('https://test-picform.lahlouhonline.com/api/requests/' + id, requestOptions)
                    data = await response.json()

                    if(!data.message){
                        error = true;
                    }else{
                        count++;
                        newObj = newObj.filter(item => item.id != id)
                    }
                }
            }
            
            // Popups and setting state object 
            if(!error){
                // Message and redirect for one request
                if(count === 1){
                    setTimeout(() => {
                        setRequests(newObj)
                        infoModal('Deleted', 'Request has been successfully deleted', true);
                        setConfirmDelete(null);
                    }, 150);
                // Message and redirect for multiple requests
                }else{
                    setTimeout(() => {
                        setRequests(newObj)
                        infoModal('Deleted', 'Requests have been successfully deleted', true);
                        setConfirmDelete(null);
                    }, 150);
                }
            }else{
                if(count === 1){
                    setTimeout(() => {
                        infoModal('Error', 'Request was unable to be deleted', true);
                        setConfirmDelete(null)
                    }, 150);
                }else{
                    setTimeout(() => {
                        infoModal('Error', 'Requests were unable to be deleted', true);
                        setConfirmDelete(null)
                    }, 150);
                }
            }
        }
    }

    // Informational Modal
    function infoModal(heading, content, autoclose = false){
        modalHeading.current.innerHTML = heading;
        modalContent.current.innerHTML = content;
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        overlay.current.className = 'show';
        popup.current.className = 'show';
        if(autoclose){
            modalClose.current.style.display = 'none';
            setTimeout(() => {
                document.body.style.overflow = "auto";
                document.body.style.marginRight = '0';
                overlay.current.className = '';
                popup.current.className = '';
            }, 2000)
        }else{
            modalClose.current.style.display = 'block';
        }
    }
    
    async function confirmOverlay(){
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
       
        overlay.current.className = 'show';
        popupConfirm.current.className = 'show';
    }

    return (
        <>
        <div className="main">
            <div className="main-content-wider">
                <h1 className="mt10">Requests</h1>

                {requests === null
                ?
                <div className="mt15 center"><h3 style={{fontWeight:'400'}}>No requests to display.</h3></div>
                :
                <>
                <div className="mt20 center">
                    Hover over a request status to see additional information on it's meaning.
                </div>

                <div className="search-wrapper">
                    <input
                        className="requests-search center"
                        name="search"
                        ref={refSearch}
                        type="text"
                        placeholder="Search by Title or ID..."
                        value={search}
                        onChange={handleChange}
                    />
                    <span
                        className="clear-search"
                        ref={refClearSearch}
                        onClick={clearSearch}
                        title="Clear Search"
                    >x</span>
                </div>

                </>
                }
                {requests != null && requests.length > 0 && <RequestsTable requests={requests} masterRequests={original.filter(item => item.group_request === 'master')} confirmDelete={confirmDelete} setConfirmDelete={setConfirmDelete} handleDelete={handleDelete} loadingOverlay={loadingOverlay} showLoading={showLoading} hideLoading={hideLoading} infoModal={infoModal} search={search} />}
                {requests && requests.length === 0 && 
                <div className="mt20 center" style={{fontSize:'18px'}}>
                    No results found for the current search.
                </div>
                }
            </div>
        </div>

        <div ref={refReturnTop} className="return-top" onClick={topFunction}>
            &#x25B2; Return to top
        </div>

        <div id="overlay" ref={overlay}></div>

        <div id="loading-overlay" ref={loadingOverlay}>
            <div className="deadCenter">
                <div className="lds-spinner">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
            </div>
        </div>

        <div id="popup" ref={popup}>
            <h2 id="modal-heading" className="center" ref={modalHeading}></h2>
            <span id="popup-close" ref={modalClose} className="close" onClick={closePopup}>&times;</span>
            <div id="modal-content" ref={modalContent} className="content center"></div>
        </div>

        <div id="popup-confirm" ref={popupConfirm}>
            <h2 id="modal-heading" ref={popupConfirmHeading} style={{textAlign:'center'}}>Are You Sure?</h2>
            <span className="close" ref={popupConfirmClose} onClick={() => {closePopup();setConfirmDelete(null)}}>&times;</span>
            <div id="modal-content" ref={popupConfirmContent} className="content center">
                Text changed through JS
            </div>
            <div className="mt10 center">
            <button className="primary-button smaller-button" onClick={(e) => {closePopup();overlay.current.className = 'show';setConfirmDelete(true);}} title="Yes">Yes</button>
            <button className="secondary-button smaller-button" onClick={() => {closePopup();setConfirmDelete(false);}} title="No">No</button>
            </div>
        </div>
        </>
    )
}