import ReactDOM from "react-dom/client";
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Components/Header'
import Footer from './Components/Footer'
import Login from "./Pages/Login";
import Home from "./Pages/Home"
import AddChange from "./Pages/AddChange"
import Remove from "./Pages/Remove"
import UpdateImage from './Pages/UpdateImage'
import ReceivingStandards from "./Pages/ReceivingStandards";
import Requests from "./Pages/Requests";
import Request from "./Pages/Request";
import Users from "./Pages/Users";
import User from "./Pages/User";
import AddUser from "./Pages/AddUser";
import Portals from "./Pages/Portals";
import Portal from "./Pages/Portal";
import AddPortal from "./Pages/AddPortal";
import ThankYou from "./Pages/ThankYou";
import NoPage from "./Pages/NoPage";
import './index.scss'
import { UserContext } from "./context/UserContext";


export default function App() {
  const [ user, setUser ] = useState(null)
  
  useEffect(() => {
    const persistUser = async () => {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
      };

      //let response = await fetch('https://test-picform.lahlouhonline.com/api/verify', requestOptions);

      let response = await fetch('https://test-picform.lahlouhonline.com/api/verify', requestOptions);
      let data = await response.json()

      if(data.username){
        await setUser(data);
      }else{
        await setUser(null)
      }
    }

    persistUser();
  }, [])

  
  // Keeping the loggedIn code here will allow the Request component's query string functionality to work.
  // Keeps the Route URL in place, but renders the login component if not logged in.
  return (
    <BrowserRouter>
      <UserContext.Provider value={{user, setUser}}>
        <Header />
        
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/receiving-standards" element={<ReceivingStandards />} />
          
          {/* Protected Routes */}
          <Route path="/" element={user ? <Home /> : <Login />} />
          <Route path="/add-change" element={user ? <AddChange /> : <Login />} />
          <Route path="/remove" element={user ? <Remove /> : <Login />} />
          <Route path="/update-image" element={user ? <UpdateImage /> : <Login />} />
          <Route path="/requests" element={user ? <Requests /> : <Login />} />
          <Route path="/request" element={user ? <Request /> : <Login />} />
          <Route path="/users" element={user ? <Users /> : <Login />} />
          <Route path="/user" element={user ? <User /> : <Login />} />
          <Route path="/add-user" element={user ? <AddUser /> : <Login />} />
          <Route path="/portals" element={user ? <Portals /> : <Login />} />
          <Route path="/portal" element={user ? <Portal /> : <Login />} />
          <Route path="/add-portal" element={user ? <AddPortal /> : <Login />} />
          <Route path="/thankyou" element={user ? <ThankYou /> : <Login />} />
          
          {/* Catch All */}
          <Route path="*" element={<NoPage />} />
        </Routes>
        
        <Footer />
      </UserContext.Provider>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);


