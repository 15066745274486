import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../context/UserContext'


export default function Header() {
  const { user, setUser } = useContext(UserContext);
  
  async function logoutHandler(){
    const requestOptions = {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const response = await fetch("https://test-picform.lahlouhonline.com/api/logout", requestOptions)
    const data = await response.json();
    window.location.href = "/";
  }

  return (
    <header>
        <div className="header-content">
            <div className="flex-item">
              <Link to="/" title="Home">
                <img className="header-logo" src="assets/images/main_logo2.jpg" alt="Company Logo" align="absmiddle" />
              </Link>
            </div>

            <div className="flex-item header-title center">
              Portal Item Change
            </div>

            <div className="flex-item">
              <ul>
                  {/* {user && <li><Link to="/" title="Home">Home</Link></li>} */}
                  {user && <li><Link to="/" title="Home">Home</Link></li>}
                  {user && <li><Link to="/requests" title="View Requests">Requests</Link></li>}
                  {user && user.role === 'admin' && <li><Link to="/portals" title="Portal Admin">Portals</Link></li>}
                  {user && user.role === 'admin' && <li><Link to="/users" title="User Admin">Users</Link></li>}
                  {user && <li><Link to="/" onClick={logoutHandler} title="Logout">Logout</Link></li>}
              </ul>
            </div>
        </div>
    </header>
  )
}
