import React, { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../context/UserContext';
import { formatDate, formatDatePicker, addBusinessDays } from '../Utils/utils'

export default function RequestsTable(props) {
    const { requests, masterRequests, confirmDelete, setConfirmDelete, handleDelete, loadingOverlay, showLoading, hideLoading, infoModal, search } = props;
    // Set the default table sort to the 'date submitted' column - descending (latest submissions first)
    const [sortConfig, setSortConfig] = useState({key: 'date_submitted', direction:'descending'});
    const navigate = useNavigate();
    // My Portals or All Portals
    const [type, setType] = useState('mine');
    

    // DOM References (to change up and down arrow for sorting)
    // Available Table Sort Icon References
    const ref_id = useRef();
    const ref_name = useRef();
    const ref_company = useRef();
    const ref_action = useRef();
    const ref_effective_date = useRef();
    const ref_date_submitted = useRef();
    const ref_request_status = useRef();

    // Column references
    const ref_all_id = useRef();
    const ref_all_name = useRef();
    const ref_all_company = useRef();
    const ref_all_action = useRef();
    const ref_all_date_submitted = useRef();
    const ref_all_request_status = useRef();

    const refAllPortals = useRef();
    const refMyPortals = useRef();

    const refSubmit = useRef();
    const refDelete = useRef();
    const refButtons = useRef();
    const refCheckAll = useRef();
    const refCheckAllContainer = useRef();
    const refSubmitError = useRef();

    const { user } = useContext(UserContext);
  
    function expandRequest(e){
        if(e.currentTarget.firstChild.children[0].className){
            //let rowId = e.currentTarget.children[1].innerHTML;
            let rowId = e.currentTarget.getAttribute('master-id');
            if(e.currentTarget.firstChild.children[0].className === 'expand-down'){
                e.currentTarget.firstChild.children[0].className = 'expand-up';
                e.currentTarget.className = 'master-highlight';
                e.currentTarget.title = 'Click to collapse group request';
                let rows = document.getElementsByTagName('tr');
                for(let a=0;a<rows.length;a++){
                    if(rows[a].getAttribute('data-master') === rowId){
                        rows[a].className = 'slave-highlight active';
                    }
                }
            }else{
                e.currentTarget.firstChild.children[0].className = 'expand-down';
                e.currentTarget.className = '';
                e.currentTarget.title = 'Click to expand group request';
                let rows = document.getElementsByTagName('tr');
                for(let a=0;a<rows.length;a++){
                    if(rows[a].getAttribute('data-master') === rowId){
                        rows[a].className = 'slave-highlight';
                    }
                }
            }
        }
    }

    async function handleSubmit(){
        let shouldSubmit = true;
        let submitTeams = true;

        // Hide Submit Error
        refSubmitError.current.classList.remove('show');
        
        // Check for mismatched portals and establish checkbox count
        let portals = [];
        let count = 0;
        let cust_num;
        let effDates = [];
        for(let a=0;a<pendingRequests.length;a++){
            if(document.getElementById('pending-check-' + pendingRequests[a].id).checked){
                portals.push(pendingRequests[a].company)
                effDates.push(pendingRequests[a].effective_date)
                count++;
                cust_num = pendingRequests[a].customer_number;
            }
        }

        // Put earliest effective date first
        // To attach to Master request
        effDates.sort();

        if(portals.length > 1){
            let firstPortal = portals[0];
            for(let a=0;a<portals.length;a++){
                if(portals[a] != firstPortal){
                    shouldSubmit = false;
                    refSubmitError.current.innerHTML = 'You may only submit requests from one portal at a time';
                    refSubmitError.current.classList.add('show');
                    break;
                }
            }
        }
        // End of portal mismatch code

        // Check for effective dates that are less than one business day in the future
        // If they are one business day in the future, make sure the next day checkbox is selected
        let todaysDate, eDate, newDate;
        for(let a=0;a<pendingRequests.length;a++){
            if(document.getElementById('pending-check-' + pendingRequests[a].id).checked){
                todaysDate = new Date();
                todaysDate.setHours(0,0,0,0);

                // Grab entered effective date at midnight
                eDate = new Date(pendingRequests[a].effective_date)

                // Add 1 business day to today's date
                const newDate = new Date(addBusinessDays(todaysDate, 1));
                
                // Date is today or earlier
                if(eDate.getTime() < newDate.getTime()){
                    refSubmitError.current.innerHTML = 'All selected requests must use a date at least 1 business day in the future.';
                    refSubmitError.current.classList.add('show');
                    shouldSubmit = false;
                    break;
                }
            }

            // Date is only 1 business day in the future
            // Not currently in place - maybe in the future
            // if(eDate.getTime() === newDate.getTime()){
            //     if(pendingRequests[a].next_day !== 'Yes'){
            //         refSubmitError.current.innerHTML = "For any requests set to 1 business day in the future, you must select the 'Next day service charge' checkbox.";
            //         refSubmitError.current.classList.add('show');
            //         shouldSubmit = false;
            //     }
            // }
        }

        
        if(shouldSubmit){
            let requestObj;
            let requestOptions;
            let data;
            let response;
            let masterId;

            let action;
            let customerNumber;

            showLoading();
            // Create master group request
            if(count > 1){
                let requestObj={
                    action: "Multiple",
                    request_status: "",
                    submitter_email: user.username,
                    name: count + " items",
                    customer_number: cust_num,
                    effective_date:effDates[0],
                    group_request: "master"
                }
               
                // Submit to PICDB
                requestOptions = {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(requestObj)
                };
                
                response = await fetch('https://test-picform.lahlouhonline.com/api/requests', requestOptions)
                            
                if(response.status === 403){
                    hideLoading();
                    infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
                }else{
                    data = await response.json()

                    if(!data.name){
                        submitTeams = false;
                    }else{
                        masterId = data.id;
                    }
                }
            }

            // Insert Effective Date on master request

            // Update status on all other requests from pending > Received
            if(count >= 1){
                requestObj={
                    request_status: "Received"
                }
            }

            let teamRequests = [];
            let num = 1;
            for(let a=0;a<pendingRequests.length;a++){
                if(document.getElementById('pending-check-' + pendingRequests[a].id).checked){
                    // Set position of slave requests
                    if(count > 1){
                        requestObj.group_request = masterId + '-' + num;
                        num++;
                    }

                    // Patch Request to PICDB to change status = Received
                    requestOptions = {
                        method: 'PATCH',
                        credentials: 'include',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(requestObj)
                    };
                    
                    response = await fetch('https://test-picform.lahlouhonline.com/api/requests/' + pendingRequests[a].id, requestOptions)
                    
                    if(response.status === 403){
                        hideLoading();
                        infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
                    }else{
                        data = await response.json()
                        
                        if(!data.name){
                            submitTeams = false;
                        }else{
                            // Set Teams Data
                            customerNumber = pendingRequests[a].customer_number;
                            teamRequests.push(data);
                        }
                    }
                }
            }

            // No errors adding master and/or associated requests - Continue On
            if(submitTeams){
                let companyName;

                // Grab Company Name from Clients Table
                let requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                let response = await fetch('https://test-picform.lahlouhonline.com/api/client/' + customerNumber, requestOptions)
                let data = await response.json();
                if(!data.error){
                    companyName = data.name;
                }

                let teamsTitle = 'Portal Item Change Request';
                let teamsType = 'New Request';
                let teamsText = "https://picform.lahlouhonline.com/request?id=" + teamRequests[0].id;
                let teamsDate = teamRequests[0].effective_date;
                action = teamRequests[0].action;
                
                if(count > 1){
                    teamsTitle = 'Portal Item Change Group Request';
                    teamsType = 'New Group Request';
                    teamsText = "First Item:   \n";
                    action = "Multiple";

                    for(let a=0;a<teamRequests.length;a++){
                        if(teamRequests[a].group_request.split('-')[1] === '1'){
                            teamsText += "https://picform.lahlouhonline.com/request?id=" + teamRequests[a].id;
                            teamsDate = teamRequests[a].effective_date;
                            break;
                        }
                    }
                }

                if(companyName.indexOf('Test Portal') !== -1){
                    teamsTitle = 'Portal Item Change Request (Test)';
                    teamsType = 'Test - Do not process';
                }
                
                // Teams Message
                const teamsObj =
                {
                    "@context": "https://schema.org/extensions",
                    "@type": "MessageCard",
                    "themeColor": "0076D7",
                    "summary": "Summary",
                    "sections": [
                        {
                            "activityTitle": teamsTitle,
                            "facts": [
                                {
                                    "name": "Type:",
                                    "value": teamsType
                                },
                                {
                                    "name": "Action:",
                                    "value": action
                                },
                                {
                                    "name": "Company:",
                                    "value": companyName
                                },
                                {
                                    "name": "Submitted By:",
                                    "value": user.username
                                },
                                {
                                    "name": "Role:",
                                    "value": user.role
                                },
                                {
                                    "name": "Effective Date:",
                                    "value": formatDate(new Date(teamsDate))
                                }
                            ],
                            "text": teamsText
                        }
                    ]
                }

                // Send to Teams
                requestOptions = {
                    method: 'POST',
                    mode: 'no-cors',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(teamsObj)
                };
            

                if(companyName.indexOf('Test Portal') === -1){
                    fetch('https://lahlouhinc.webhook.office.com/webhookb2/a51eee01-4ded-4410-ae26-8ecd3f547d46@55d9408b-9060-4745-9c50-44dba74d1b39/IncomingWebhook/c7cb6c6f43bc44a69ce761f64df16b3b/b11fe407-83f0-4667-96ae-7740b0ea7b21', requestOptions)
                }
                
                requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json'}
                };

                // Send Single Request Email
                if(count === 1){
                    response = await fetch('https://test-picform.lahlouhonline.com/api/single-email-submit/' + teamRequests[0].id, requestOptions) 
                // Send Group Request Email
                }else{
                    response = await fetch('https://test-picform.lahlouhonline.com/api/group-email-submit/' + masterId, requestOptions)
                }

                //data = await response.json();

                hideLoading();

                if(count === 1){
                    navigate('/thankyou', { state: { type: 'request' } });
                }else{
                    navigate('/thankyou', { state: { type: 'group' } });
                }
            }
        }
    }

    let sortedRequests, slaveRequests, pendingRequests = null;
    ////////////////////
    // No Search
    ////////////////////
    if(search === ''){
        // Pull everything (except slave requests)    
        sortedRequests = [...requests].filter(item => item.request_status !== 'Pending' && (item.group_request === 'master' || item.group_request === null || item.group_request === ''));
        // Pull Slave Requests Only
        slaveRequests = [...requests].filter(item => item.group_request !== 'master' && item.group_request !== null && item.group_request !== '')
    ////////////////////
    // Search
    ////////////////////
    }else{
        // Pull Everything
        sortedRequests = [...requests].filter(item => item.request_status !== 'Pending');
    }

    pendingRequests = [...requests].filter(item => item.request_status === 'Pending' && item.submitter_email === user.username);
    
    /////////////////////////////
    // Column sorting
    // Resets on page load
    ///////////////////////////// 
    
    // Sort with numeric
    if(sortConfig.key === 'id'){
        sortedRequests.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        if(slaveRequests && slaveRequests.length > 0){
            slaveRequests.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
    // Sort with alphanumeric
    }else{
        sortedRequests.sort((a, b) => {
            a = a[sortConfig.key].toLowerCase()
            b = b[sortConfig.key].toLowerCase()
            if(a == b) return 0
            if (a < b) return sortConfig.direction === 'ascending' ? -1 : 1;
            if (a > b) return sortConfig.direction === 'ascending' ? 1 : -1;
        });

        if(slaveRequests && slaveRequests.length > 0){
            slaveRequests.sort((a, b) => {
                a = a[sortConfig.key].toLowerCase()
                b = b[sortConfig.key].toLowerCase()
                if(a == b) return 0
                if (a < b) return sortConfig.direction === 'ascending' ? -1 : 1;
                if (a > b) return sortConfig.direction === 'ascending' ? 1 : -1;
            });
        }
    }

    // Set intitial sorting arrow on page load
    useEffect(() => {
        if(search === ''){
            if(sortConfig.direction === 'ascending'){
                //eval('ref_' + sortConfig.key + '.current.className="arrow-up"');
                if(eval('ref_' + sortConfig.key).current){
                    eval('ref_' + sortConfig.key).current.className = "arrow-up";
                }
            }else{
                if(eval('ref_' + sortConfig.key).current){
                    eval('ref_' + sortConfig.key).current.className = "arrow-down";
                }
            }
        }
    }, [search])
    

    //////////////////////
    // My Portals    
    //////////////////////
    let showMine = false;
    if(user.customer_number.split(',')[0] !== '1' && user.role === 'sales'){
        showMine = [...sortedRequests].filter(item => item.request_status !== 'Pending' && user.customer_number.split(',').indexOf(item.customer_number) != -1).length > 0;  
        if(type === 'mine' && showMine){
            sortedRequests = [...sortedRequests].filter(item => item.request_status !== 'Pending' && user.customer_number.split(',').indexOf(item.customer_number) != -1);
        }
    }
    
    
    /////////////////////////////////////////////
    // Insert Slave Requests
    // Non Search
    // Keeps header and slave requests together
    ////////////////////////////////////////////
    if(search === ''){
        let startIndex, split;
        for(let a=0;a<masterRequests.length;a++){
            for(let b=0;b<sortedRequests.length;b++){
                if(sortedRequests[b].id == masterRequests[a].id){
                    startIndex = b + 1;
                    for(let c=0;c<slaveRequests.length;c++){
                        if(slaveRequests[c].group_request.split('-')[0] == masterRequests[a].id){
                            sortedRequests.splice(startIndex,0,slaveRequests[c])
                            startIndex += 1;
                        } 
                    }
                    break;
                }
            }
        }

        // Reset Results Number on Header
        let count = 0;
        for(let a=0;a<sortedRequests.length;a++){
            count = 0;
            if(sortedRequests[a].group_request === 'master'){
                for(let b=0;b<sortedRequests.length;b++){
                    if(sortedRequests[b].group_request !== 'master' && sortedRequests[b].group_request !== null && sortedRequests[b].group_request !== ''){
                        split = sortedRequests[b].group_request.split('-');
                        if(split[0] == sortedRequests[a].id && split.length > 1){
                            count += 1
                        }
                    }
                }
                sortedRequests[a].name = count + ' item'
                if(count > 1){
                    sortedRequests[a].name += 's';
                }
            }
        }



    ////////////////////////////////
    // Strip and Re-Insert Headers
    // For Search
    ////////////////////////////////
    }else{
        if(masterRequests){
            // Remove all headers to start
            for(let a=0;a<sortedRequests.length;a++){
                if(sortedRequests[a].group_request === 'master'){
                    sortedRequests.splice(a,1);
                    if(a !==0) a-=1;
                }
            }

            // Sort by Group Request ID's
            // Lumps them together 
            sortedRequests.sort((a, b) => {
                a = a['group_request']
                b = b['group_request']
                if(a == b) return 0
                if (a < b) return sortConfig.direction === 'ascending' ? -1 : 1;
                if (a > b) return sortConfig.direction === 'ascending' ? 1 : -1;
            });

            // Insert any needed headers
            let split;
            for(let a=0;a<masterRequests.length;a++){
                for(let b=0;b<sortedRequests.length;b++){
                    if(sortedRequests[b].group_request !== 'master' && sortedRequests[b].group_request !== null && sortedRequests[b].group_request !== ''){
                        split = sortedRequests[b].group_request.split('-');
                        if(split[0] == masterRequests[a].id && split.length > 1){
                            // Index b, remove 0 elements, insert header
                            sortedRequests.splice(b,0,masterRequests[a])
                            break;
                        }
                    }
                }
            }

            // Matching Results for Header
            let count = 0;
            for(let a=0;a<sortedRequests.length;a++){
                count = 0;
                if(sortedRequests[a].group_request === 'master'){
                    for(let b=0;b<sortedRequests.length;b++){
                        if(sortedRequests[b].group_request !== 'master' && sortedRequests[b].group_request !== null && sortedRequests[b].group_request !== ''){
                            split = sortedRequests[b].group_request.split('-');
                            if(split[0] == sortedRequests[a].id && split.length > 1){
                                count += 1
                            }
                        }
                    }
                    sortedRequests[a].name = count + ' matching item'
                    if(count > 1){
                        sortedRequests[a].name += 's';
                    }
                }
            }
        }
    }

    const requestSort = (key, type) => {
        // Remove table sorting arrows - All Requests
        ref_id.current.className = '';
        if(ref_company.current) ref_company.current.className = '';
        ref_name.current.className = '';
        ref_action.current.className = '';
        ref_effective_date.current.className = '';
        ref_date_submitted.current.className = '';
        ref_request_status.current.className = '';
        // Eval to allow use of dynamic variable name
        eval('ref_' + key + '.current.className="arrow-up"');
        
        let direction = 'ascending';
        
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
            eval('ref_' + key + '.current.className="arrow-down"');
        }

        setSortConfig({ key, direction, type });
    }

    //Checkbox Click (Group Request)
    function handleChange(e){
        //refSubmitError.current.style.display = 'none';
        refSubmitError.current.classList.remove('show');

        let count = 0;

        for(let a=0;a<pendingRequests.length;a++){
            if(document.getElementById('pending-check-' + pendingRequests[a].id).checked){
                count++;
            }
        }

        if(count === 0){
            refSubmit.current.disabled = true;
            refSubmit.current.title = '';
            refDelete.current.disabled = true;
            refDelete.current.title = '';
        }else{
            refSubmit.current.disabled = false;
            refSubmit.current.onclick=function(){handleSubmit()}
            refDelete.current.disabled = false;
            refDelete.current.onclick=function(){handleDelete()}
        }
        
        if(count === 1){
            refSubmit.current.title = 'Submit selected request'
            refDelete.current.title = 'Delete selected request'
        }
        if(count > 1){
            refSubmit.current.title = 'Submit selected requests'
            refDelete.current.title = 'Delete selected requests'
        }

        if(count < pendingRequests.length){
            refCheckAll.current.checked = false;
            refCheckAllContainer.current.title = 'Select all items';
        }

        if(count === pendingRequests.length){
            refCheckAll.current.checked = true;
            refCheckAllContainer.current.title = 'Unselect all items';
        }
    }

    function rowClick(e){
        // Clicked on the edit icon or status box
        if(e.target.className === 'edit-icon' || e.target.className.indexOf('highlight') !== -1){
            navigate('/request?id=' + e.target.parentNode.parentNode.getAttribute('data-id'))
        // Clicked on the trash icon (not currently being used)
        }else if (e.target.className === 'trash-icon'){
        // Clicked on the checkbox
        }else if (e.target.type === 'checkbox' || e.target.className === 'checkbox-container' || e.target.className === 'checkmark'){
        }
        // Clicked anywhere else on the table row
        else{
            if(e.target.parentNode.parentNode.getAttribute('data-master')){
                navigate('/request?id=' + e.target.parentNode.parentNode.getAttribute('data-id'))
            }else{
                navigate('/request?id=' + e.target.parentNode.getAttribute('data-id'))  
            }
        }
    }

    function checkAll(){
        //refSubmitError.current.style.display = 'none';
        refSubmitError.current.classList.remove('show');
        
        if(refCheckAll.current.checked){
            for(let a=0;a<pendingRequests.length;a++){
                document.getElementById('pending-check-' + pendingRequests[a].id).checked = true;
            }
            refCheckAllContainer.current.title = 'Unselect all items'
            refSubmit.current.disabled = false;
            refSubmit.current.onclick=function(){handleSubmit()}
            refDelete.current.disabled = false;
            refDelete.current.onclick=function(){handleDelete()}
        }else{
            for(let a=0;a<pendingRequests.length;a++){
                document.getElementById('pending-check-' + pendingRequests[a].id).checked = false;
            }
            refCheckAllContainer.current.title = 'Select all items'
            refSubmit.current.disabled = true;
            refSubmit.current.title = '';
            refDelete.current.disabled = true;
            refDelete.current.title = '';
        }
    }

    function getHighlight(group_request){
        if(group_request !== null && group_request !== ''){
            if(group_request === 'master'){
                //return 'master-highlight';
            }else{
                //return 'slave-highlight';
                return 'slave';
            }
        }
        return '';
    }

    function move(input, from, to) {
        let numberOfDeletedElm = 1;
        const elm = input.splice(from, numberOfDeletedElm)[0];
        numberOfDeletedElm = 0;
        input.splice(to, numberOfDeletedElm, elm);
    }

    // Placeholder function to highlight in real-time during a search
    function textHighlight(){
        //innerHTML = innerHTML.substring(0,index) + "<span class='highlight'>" + innerHTML.substring(index,index+text.length) + "</span>" + innerHTML.substring(index + text.length);
    }

    function changeType(str){
        if(str === 'mine'){
            setType('mine');
            if(refMyPortals.current){
                refMyPortals.current.classList.add('active');
                refMyPortals.current.title = '';
            }
            if(refAllPortals.current){
                refAllPortals.current.classList.remove('active');
                refAllPortals.current.title = 'View all portals'
            }
        }else{
            setType('all');
            if(refMyPortals.current){
                refMyPortals.current.classList.remove('active');
                refMyPortals.current.title = 'View my portals';
            }
            if(refAllPortals.current){
                refAllPortals.current.classList.add('active')
                refAllPortals.current.title = '';
            }
        }
    }

    let pendingStyle, availStyle = '';
    if(user.role === 'admin' || user.role === 'sales'){
        pendingStyle = 'internal-pending-table';
        availStyle = 'internal-request-table';
    }else{
        pendingStyle = 'external-pending-table';
        availStyle = 'external-request-table'
    }

    if(showMine){
        pendingStyle += ' mt10';
        availStyle += ' mt10';
    }else{
        pendingStyle += ' mt15';
        availStyle += ' mt15';
    }

   
    return (
        <>
        {/* Pending Requests Table */}
        
        {pendingRequests && pendingRequests.length > 0 &&
        <>
        <h2 className="table-width mt30" style={{textAlign:'center'}}>Pending Requests</h2>

        <div className="mt10 center">
            Please select the request(s) you would like to submit or delete.
        </div>
        
        <table className={pendingStyle} cellPadding="0" cellSpacing="0">
        <thead>
            <tr>
                <th>
                    <label ref={refCheckAllContainer} className="checkbox-container" title="Select all items">
                        <input 
                            type="checkbox"
                            name="check-all"
                            ref={refCheckAll}
                            id='check-all'
                            onChange={checkAll}
                            //value="yes"
                        />
                        <span className="checkmark"></span>
                    </label>
                </th>
                {(user.role === 'admin' || user.role === 'sales') && <th>Portal</th>}
                <th>Action</th>
                <th>Product Title</th>
                <th>Effective</th>
                <th>Saved</th>
                <th>Status</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {pendingRequests.map(request => {
                //if(request.group_request === null || request.group_request === ''){
                return (
                <tr key={request.id} data-id={request.id} title="Click to view/edit request" onClick={rowClick}>
                    <td>
                        <label className="checkbox-container">
                            <input 
                                type="checkbox"
                                name="pending-check"
                                id={"pending-check-" + request.id}
                                //defaultChecked={state.supplied_image === 'yes'}
                                onChange={handleChange}
                                value="yes"
                            />
                            <span className="checkmark"></span>
                        </label>
                    </td>
                    {(user.role === 'admin' || user.role === 'sales') && <td data-label="Portal">{request.company}</td>}
                    <td data-label="Action">{request.action}</td>
                    <td data-label="Name">{request.name}</td>
                    <td data-label="Effective">{formatDatePicker(new Date(request.effective_date))}</td>
                    <td data-label="Saved">{formatDate(new Date(request.date_submitted))}</td>
                    <td data-label="Status">
                        <span className="highlight dark-draft" title="Request is ready to be reviewed and submitted">Pending</span>                    
                    </td>
                    <td>
                        <div className="edit-icon" title="Edit request" onClick={() => navigate('/request?id=' + request.id)}></div>
                        {/* <div className="trash-icon" onClick={() => singleDelete(request.id)} title="Delete request"></div> */}
                    </td>
                </tr>
                )    
                //}
                
            })}
        </tbody>
        </table>

        {/* Submit Error */}
        <div ref={refSubmitError} className="small-width center errorMaster"></div>

        {/* Submit and delete buttons */}
        <div ref={refButtons} className="pending-buttons">
            <button 
                ref={refSubmit} 
                className="primary-button" 
                disabled={true}
                onClick={handleSubmit}
            >Submit</button>

            <button 
                ref={refDelete} 
                className="reject-button" 
                disabled={true}
            >Delete</button>
        </div>
        </>
        }


        {pendingRequests && pendingRequests.length > 0 && sortedRequests && sortedRequests.length > 0 && 
        <h2 className="table-width mt40" style={{textAlign:'center'}}>All Requests</h2>
        }

        {user.customer_number.split(',')[0] !== '1' && user.role === 'sales' && showMine &&
        <div className="change-portal-type mt10">
            <a 
                ref={refMyPortals} 
                title=""
                className={type ==='mine' ? "active" : "" }
                onClick={type === 'all' ? (() => changeType('mine')) : null}
            >My Portals</a> | 
            <a 
                ref={refAllPortals} 
                title="View all portals"
                className={type === 'all' ? "active" : ""}
                onClick={type === 'mine' ? (() => changeType('all')) : null}
            >All Portals</a>
        </div>
        }

        
        {/* All Requests Table */}
        {sortedRequests && sortedRequests.length > 0 && 
        <>
        <table className={availStyle} cellSpacing="0" cellPadding="0">
        <thead>
            <tr>
                {/* ID Column */}
                <th 
                  onClick={search == '' ? () => {requestSort('id', 'all')} : () => {return false}} 
                  title={search == '' ? "Sort by Request ID" : ""} 
                  className={search !== '' ? 'disabled' : ''}
                >
                    ID {search == '' ? <span ref={ref_id}></span> : ''}
                </th>

                {/* Portal Column */}
                {(user.role === 'admin' || user.role === 'sales') &&
                <th 
                    onClick={search == '' ? () => {requestSort('company', 'all')} : () => {return false}} 
                    title={search == '' ? "Sort by Portal" : ""}
                    className={search !== '' ? 'disabled' : ''}
                >
                    Portal {search == '' ? <span ref={ref_company}></span> : ''}
                </th>
                }
                
                {/* Action Column */}
                <th
                    onClick={search == '' ? () => {requestSort('action', 'all')} : () => {return false}}
                    title={search == '' ? "Sort by Action" : ""}
                    className={search !== '' ? 'disabled' : ''}
                >
                    Action {search == '' ? <span ref={ref_action}></span> : ''}
                </th>
                
                {/* Product Title Column */}
                <th
                    onClick={search == '' ? () => {requestSort('name', 'all')} : () => {return false}}
                    title={search == '' ? "Sort by Product Title" : ""}
                    className={search !== '' ? 'disabled' : ''}
                >
                    Product Title {search == '' ? <span ref={ref_name}></span> : ''}
                </th>

                {/* Effective Date Column */}
                <th 
                    onClick={search == '' ? () => {requestSort('effective_date', 'all')} : () => {return false}}
                    title={search == '' ? "Sort by Effective Date" : ""}
                    className={search !== '' ? 'disabled' : ''}
                >
                    Effective {search == '' ? <span ref={ref_effective_date}></span> : ''}
                </th>
                
                {/* Date Submitted Column */}
                <th 
                    onClick={search == '' ? () => {requestSort('date_submitted', 'all')} : () => {return false}}
                    title={search == '' ? "Sort by Submission Date" : ""}
                    className={search !== '' ? 'disabled' : ''}
                >
                    Submitted {search == '' ? <span ref={ref_date_submitted}></span> : ''}
                </th>
                
                {/* Request Status Column */}
                <th
                    onClick={search == '' ? () => {requestSort('request_status', 'all')} : () => {return false}}
                    title={search == '' ? "Sort by Request Status" : ""}
                    className={search !== '' ? 'disabled' : ''}
                >
                    Status {search == '' ? <span ref={ref_request_status}></span> : ''}
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {sortedRequests.map(request => {
                // Master Request
                if(request.group_request === 'master'){

                    return (
                    <tr 
                        key={request.id}
                        data-id={request.id}       
                        title={search === '' ? "Click to expand group request" : "Click to collapse group request"} 
                        className={search !== '' ? "master-highlight" : undefined} 
                        master-id={request.id} 
                        onClick={(event) => expandRequest(event)}
                    >
                        <td><span className={search === '' ? "expand-down" : "expand-up"}></span></td>
                        {(user.role === 'admin' || user.role === 'sales') && <td data-label="Portal">{request.company}</td>}
                        <td data-label="Action">{request.action}</td>
                        <td data-label="Name">
                            {request.name}
                        </td>
                        <td data-label="Effective">{formatDatePicker(new Date(request.effective_date))}</td>
                        <td data-label="Submitted">{formatDate(new Date(request.date_submitted))}</td>
                        <td data-label="Status"></td>
                        <td></td>
                    </tr>
                    )
                }

                // Slave Request
                if(request.group_request !== 'master' && request.group_request !== null && request.group_request !== ''){
                    let masterId = request.group_request.split('-')[0];
                    return(
                    <tr 
                        key={request.id} 
                        data-id={request.id}     
                        title="Click to view request" 
                        className={search === '' ? "slave-highlight" : "slave-highlight active"} 
                        data-master={masterId}
                        onClick={rowClick} 
                    >
                        <td data-label="ID">
                            <div>{request.id}</div>
                        </td>
                        {(user.role === 'admin' || user.role === 'sales') && <td data-label="Portal"><div>{request.company}</div></td>}
                        <td data-label="Action"><div>{request.action}</div></td>
                        <td data-label="Name"><div>{request.name}</div></td>
                        <td data-label="Effective"><div>{formatDatePicker(new Date(request.effective_date))}</div></td>
                        <td data-label="Submitted"><div>{formatDate(new Date(request.date_submitted))}</div></td>
                        <td data-label="Status">
                            <div>
                            {request.request_status === 'In Progress' && <span className="highlight in-progress" title="Request is actively being worked on. Next status update will be flagged as 'Completed'">{request.request_status}</span>}
                            {request.request_status === 'Fix' && <span className="highlight fix" title="Request has items for the submitter to fix">{request.request_status}</span>}
                            {request.request_status === 'Received' && <span className="highlight received" title="Request has been received and is pending approval">{request.request_status}</span>}
                            {request.request_status === 'Completed' && <span className="highlight completed" title="Request has been completed and is in production">{request.request_status}</span>}
                            {request.request_status === 'Cancelled' && <span className="highlight cancelled" title="Request has been cancelled and will not be processed">{request.request_status}</span>}
                            </div>
                        </td>
                    </tr>
                    )
                }

                // Single Item Request
                if(request.group_request !== 'master' && (request.group_request === null || request.group_request === '')){
                    return (
                    <tr 
                        key={request.id} 
                        data-id={request.id} 
                        title="Click to view request" 
                        onClick={rowClick} 
                        //className={getHighlight(request.group_request)}
                    >
                        <td data-label="ID">
                            {/* {request.group_request === 'master' ? <span className="expand-down"></span> : request.id} */}
                            {request.id}
                        </td>
                        {(user.role === 'admin' || user.role === 'sales') && <td data-label="Portal">{request.company}</td>}
                        <td data-label="Action">{request.action}</td>
                        <td data-label="Name">
                            {request.name}
                        </td>
                        <td data-label="Effective">{formatDatePicker(new Date(request.effective_date))}</td>
                        <td data-label="Submitted">{formatDate(new Date(request.date_submitted))}</td>
                        <td data-label="Status">
                            {request.request_status === 'In Progress' && <span className="highlight in-progress" title="Request is actively being worked on. Next status update will be flagged as 'Completed'">{request.request_status}</span>}
                            {request.request_status === 'Fix' && <span className="highlight fix" title="Request has items for the submitter to fix">{request.request_status}</span>}
                            {request.request_status === 'Received' && <span className="highlight received" title="Request has been received and is pending approval">{request.request_status}</span>}
                            {request.request_status === 'Completed' && <span className="highlight completed" title="Request has been completed and is in production">{request.request_status}</span>}
                            {request.request_status === 'Cancelled' && <span className="highlight cancelled" title="Request has been cancelled and will not be processed">{request.request_status}</span>}
                        </td>
                        <td>
                            <div className="edit-icon" title="Edit request"></div>
                        </td>
                    </tr>
                    )
                }
            })}
        </tbody>
        </table>
        </>
        }
        </>
    )
}

