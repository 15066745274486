import React, { useState, useRef, useEffect, useContext } from 'react'
import { UserContext } from '../context/UserContext';
import { useNavigate } from "react-router-dom";
import { formatDatePicker } from '../Utils/utils'

export default function AddChange() {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [confirm, setConfirm] = useState(null)
    // Does logged in user have pending requests or not
    const [pending, setPending] = useState(false)
    const [pendingRequests, setPendingRequests] = useState(null);
    const [copiedProduct, setCopiedProduct] = useState(false);
    const [original, setOriginal] = useState(null);
    const [copiedImage, setCopiedImage] = useState('');

    let companyName = null;

    // DOM References
    const refAction = useRef();
    const refActionError = useRef();
    const refPartNum = useRef();
    const refPartNumError = useRef();
    const refName = useRef();
    const refNameError = useRef();
    const refArrivalDate = useRef();
    const refArrivalDateError = useRef();
    const refDate = useRef();
    const refDateError = useRef();
    const refDateCharge = useRef();
    const refDisplayPrice = useRef();
    const refDisplayPriceError = useRef();
    const refCreditPrice = useRef();
    const refCreditPriceError = useRef();
    const refReturnTop = useRef();
    const refNextDay = useRef();
    const refImage = useRef();
    const refImageError = useRef();
    const refPortal = useRef();
    const refPortalError = useRef();
    const refAttach = useRef();
    const refLahlouh = useRef();
    const refNoImage = useRef();
    const refErrors = useRef();
    const refPortalList = useRef();
    const refImageDetails = useRef();
    const refVeraCore = useRef();
    const refVeraCoreError = useRef();
    const refOwner = useRef();
    const refOwnerError = useRef();
    const refSelectCopy = useRef();
    const refUOM = useRef();
    const refUOMError = useRef();
    const refOrderQty = useRef();
    const refOrderQtyError = useRef();
    const refUserGroup = useRef();
    const refUserGroupError = useRef();
    const refCategory = useRef();
    const refCategoryError = useRef();
    const refExpectedQty = useRef();
    const refExpectedQtyError = useRef();
    const refNotes = useRef();
    const refNotesError = useRef();
    const refBatchFreq = useRef();
    const refEstimate = useRef();
    const refEstimateError = useRef();
    const refHoldDate = useRef();
    const refLiveImage = useRef();
    const refShortDesc = useRef();
    const refCatalog = useRef();
    const refProduct = useRef();
    const linkCatalog = useRef();
    const linkProduct = useRef();
    const refPreview = useRef();
        

    //Overlay References
    const overlay = useRef();
    const loadingOverlay = useRef();
    const popup = useRef();
    const modalHeading = useRef();
    const modalContent = useRef();
    const modalClose = useRef();
    const popupConfirm = useRef();
    const popupConfirmHeading = useRef();
    const popupConfirmContent = useRef();
    const popupConfirmClose = useRef();
        
    const [state, setState] = useState(
        {
          action:'',
          image:'none',
          //supplied_image: '',
          partnum:'',
          append_partnum: 'yes',
          name:'',
          //short_desc: '',
          description:'',
          display_price: '',
          uom:'',
          orderable_qty:'',
          re_order_point:'',
          user_groups:'',
          category:'',
          arrival_date:'',
          expected_qty:'',
          batch_freq:'',
          item_type: 'other',
          approval_required:'no',
          international:'no',
          effective_date:'',
          next_day: '',
          client_notes:'',
          vc_product_id:'',
          owner:'',
          job_number:'',
          estimate_number:'',
          credit_price:'',
          customer_number:'',
          hold_date: 'no'
        }
    );
        
    // Change handler for text, date, select, and textarea
    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));

        if(name === 'description'){
            refShortDesc.current.innerHTML = value;
        }

        if(name === "effective_date"){
            refDateCharge.current.classList.remove("show");
            // Next Day Logic
            let nextDay = false;
            const todaysDate = new Date();
            todaysDate.setHours(0,0,0,0);
            
            // Grab entered effective date at midnight
            const eDate = new Date(value + ' 00:00:00');

            // Add 1 business day to today's date
            const newDate = new Date(addBusinessDays(todaysDate, 1));

            // Date is 1 business day in the future
            if(eDate.getTime() === newDate.getTime()){
                refDateCharge.current.classList.add("show");
            } 
        }

        if(name === 'image'){
            if(refAttach.current.checked){
                refImageDetails.current.innerHTML = '';
                if(refImage.current.files && refImage.current.files.length > 0){
                    let str = refImage.current.files.length;
                    str += ' image';
                    if(refImage.current.files.length > 1) str += 's';
                    str += ' (from upload)';
                    refImageDetails.current.innerHTML = str; 
                }else{
                    if(copiedImage != '' && copiedImage != null){
                        let split = copiedImage.split(',');
                        let str = split.length + ' image';
                        if(split.length > 1) str += 's';
                        str += ' (from copied product)'
                        refImageDetails.current.innerHTML = str;
                    }
                }
            }
            if(refLahlouh.current.checked) refImageDetails.current.innerHTML = '';
            if(refNoImage.current.checked) refImageDetails.current.innerHTML = '';
        }
        //refImageDetails.current.innerHTML = str; 
    };

    // Confirm Modal
    async function confirmOverlay(){
        let text = '<div class="mt10 center">Would you like to enter another request?</div>';

        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
       
        popupConfirmContent.current.innerHTML = text;
        overlay.current.className = 'show';
        popupConfirm.current.className = 'show';
    }

    function previewOverlay(){
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';

        //popupConfirmContent.current.innerHTML = text;
        overlay.current.className = 'show';
        refPreview.current.classList.add("show");
    }

    // Form Submit & Validation
    async function handleSubmit(){
        let shouldSubmit = true;
        let nextDay = false;

        refAction.current.classList.remove('error-outline');
        refActionError.current.style.display='none';
        refName.current.classList.remove('error-outline');
        refNameError.current.style.display='none';
        refPartNum.current.classList.remove('error-outline');
        refPartNumError.current.style.display='none';
        refDate.current.classList.remove('error-outline');
        refDateError.current.style.display='none';
        refImageError.current.style.display = 'none';
        refDisplayPrice.current.classList.remove('error-outline');
        refDisplayPriceError.current.style.display='none';
        refErrors.current.classList.remove('show');
        if(refUOM.current) refUOM.current.classList.remove('error-outline');
        if(refUOMError.current) refUOMError.current.style.display='none';
        if(refOrderQty.current) refOrderQty.current.classList.remove('error-outline');
        if(refOrderQtyError.current) refOrderQtyError.current.style.display='none';
        if(refUserGroup.current) refUserGroup.current.classList.remove('error-outline');
        if(refUserGroupError.current) refUserGroupError.current.style.display='none';
        if(refVeraCore.current) refVeraCore.current.classList.remove('error-outline');
        if(refVeraCoreError.current) refVeraCoreError.current.style.display='none';
        if(refCategory.current) refCategory.current.classList.remove('error-outline');
        if(refCategoryError.current) refCategoryError.current.style.display='none';
        if(refOwner.current) refOwner.current.classList.remove('error-outline');
        if(refOwnerError.current) refOwnerError.current.style.display='none';
        if(refArrivalDate.current) refArrivalDate.current.classList.remove('error-outline');
        if(refArrivalDateError.current) refArrivalDateError.current.style.display='none';
        if(refExpectedQty.current) refExpectedQty.current.classList.remove('error-outline');
        if(refExpectedQtyError.current) refExpectedQtyError.current.style.display='none';

        if(user.role === 'admin' || user.role === 'sales'){
            refEstimate.current.classList.remove('error-outline');
            refEstimateError.current.style.display='none';
            refPortal.current.classList.remove('error-outline');
            refPortalError.current.style.display='none';
            refCreditPrice.current.classList.remove('error-outline');
            refCreditPriceError.current.style.display='none';
        }
        if(!refNextDay.current.checked) refDateCharge.current.style.display = 'none';

        // Action Validation
        if(state.action === '' || state.action.indexOf('Select') !== -1){
            refAction.current.classList.add('error-outline');
            refActionError.current.style.display='block';
            shouldSubmit = false;
        }

        // Image Validation - Correct Type (when attached)
        if(refAttach.current.checked){
            if(refImage.current.files[0]){
                let type;
                for(let a=0;a<refImage.current.files.length;a++){
                    type = refImage.current.files[a].type;
                    if(type.indexOf('jpeg') === -1 && type.indexOf('png') === -1){
                        refImageError.current.innerHTML = 'Please upload images in JPG or PNG format.'
                        refImageError.current.style.display='block';
                        shouldSubmit = false;
                        break;
                    }
                }
                if(refImage.current.files.length > 5){
                    refImageError.current.innerHTML = 'Please upload a maximum of 5 files.'
                    refImageError.current.style.display='block';
                    shouldSubmit = false;
                }
            }else{
                if(!copiedProduct){
                    refImageError.current.innerHTML = 'Please upload at least one image.'
                    refImageError.current.style.display='block';
                    shouldSubmit = false;
                }
            }
        }

        // Part Number Validation
        if(state.partnum === ''){
            refPartNumError.current.innerHTML = 'Please enter a part number.';
            refPartNum.current.classList.add('error-outline');
            refPartNumError.current.style.display='block';
            shouldSubmit = false;
        }

        // Name Validation
        if(state.name === ''){
            refName.current.classList.add('error-outline');
            refNameError.current.style.display='block';
            shouldSubmit = false;
        }

        // UOM Validation
        if(state.action.indexOf('Add') !== -1){
            if(state.uom === ''){
                refUOM.current.classList.add('error-outline');
                refUOMError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Orderable Qty Validation
        if(state.action.indexOf('Add') !== -1){
            if(state.orderable_qty === ''){
                refOrderQty.current.classList.add('error-outline');
                refOrderQtyError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // User Group Validation
        if(state.action.indexOf('Add') !== -1){
            if(state.user_groups === ''){
                refUserGroup.current.classList.add('error-outline');
                refUserGroupError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Category Validation
        if(state.action.indexOf('Add') !== -1){
            if(state.category === ''){
                refCategory.current.classList.add('error-outline');
                refCategoryError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Expected Arrival Date Validation
        if(state.action.indexOf('Add') !== -1){
            if(state.arrival_date === ''){
                refArrivalDate.current.classList.add('error-outline');
                refArrivalDateError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Expected Qty Validation
        if(state.action.indexOf('Add') !== -1){
            if(state.expected_qty === ''){
                refExpectedQty.current.classList.add('error-outline');
                refExpectedQtyError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Effective Date Validation
        if(state.effective_date === ''){
            refDateError.current.innerHTML = 'Please enter an effective date.';
            refDate.current.classList.add('error-outline');
            refDateError.current.style.display='block';
            shouldSubmit = false;
        }

        if(state.effective_date !== ''){
            // Grab todays date and set hours to exactly midnight
            const todaysDate = new Date();
            todaysDate.setHours(0,0,0,0);

            // Grab entered effective date at midnight
            const eDate = new Date(state.effective_date + ' 00:00:00');

            // Add 1 business day to today's date
            const newDate = new Date(addBusinessDays(todaysDate, 1));
            
            // Date is today or earlier
            if(eDate.getTime() < newDate.getTime()){
                refDateError.current.innerHTML = 'Please choose a date at least 1 business day in the future.';
                refNextDay.current.checked = false;
                refDateCharge.current.style.display='none';
                refDate.current.classList.add('error-outline');
                refDateError.current.style.display='block';
                shouldSubmit = false;
            }

            // Date is only 1 business day in the future
            // Add next day messaging
            if(eDate.getTime() === newDate.getTime()){
                nextDay = true;
                if(!refNextDay.current.checked){
                    refDate.current.classList.add('error-outline');
                    refDateCharge.current.style.display='flex';
                    shouldSubmit = false;
                }
            }
        }

        // Notes Validation
        if(state.action.indexOf('Change') !== -1){
            if(state.client_notes === ''){
                //refNotesError.current.innerHTML = 'Please enter an effective date.';
                refNotes.current.classList.add('error-outline');
                refNotesError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Display Price Validation
        if(state.display_price != ''){
            if(isNaN(state.display_price)){
                refDisplayPrice.current.classList.add('error-outline');
                refDisplayPriceError.current.innerHTML = 'Display price must be numeric.';
                refDisplayPriceError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Credit Price Validation
        if(state.credit_price != ''){
            if(isNaN(state.credit_price)){
                refCreditPrice.current.classList.add('error-outline');
                refCreditPriceError.current.innerHTML = 'Credit price must be numeric.';
                refCreditPriceError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Admin / Sales Validations        
        if(user.role === 'admin' || user.role === 'sales'){
            // VeraCore Product ID
            if(state.vc_product_id === ''){
                refVeraCoreError.current.innerHTML = 'Please enter the VeraCore Product ID.';
                refVeraCore.current.classList.add('error-outline');
                refVeraCoreError.current.style.display='block';
                shouldSubmit = false;
            }

            // VeraCore Product ID RegEx
            if(state.vc_product_id !== ''){
                const regex = /^[0-9A-Za-z\s-]+$/
                if(!state.vc_product_id.match(regex)){
                    refVeraCoreError.current.innerHTML = 'Only numbers, letters and dashes are allowed in the part number.';
                    refVeraCore.current.classList.add('error-outline');
                    refVeraCoreError.current.style.display='block';
                    shouldSubmit = false;
                }
            }

            // POD Validation (one thing turns on another)
            if(refBatchFreq.current.options[0].selected || refBatchFreq.current.options[1].selected){
                
            }else{
                
                if(refEstimate.current.value === ''){
                    refEstimate.current.classList.add('error-outline');
                    refEstimateError.current.style.display='block';
                    shouldSubmit = false;
                }
            }
            
            // Portal Validation (for Admin / Sales)
            if(refPortal.current.value.indexOf('Select an') != -1){
                refPortal.current.classList.add('error-outline');
                refPortalError.current.style.display='block';
                shouldSubmit = false;
            }else{
                // Grab Company Name from Clients Table
                let requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                let response = await fetch('https://test-picform.lahlouhonline.com/api/client/' + state.customer_number, requestOptions)
                let data = await response.json();
                if(!data.error){
                    companyName = data.name;
                }
            }
        // Add Company Name for Teams Message when role = External
        }else{
            let requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' }
            };

            let response = await fetch('https://test-picform.lahlouhonline.com/api/client/' + user.customer_number, requestOptions)
            let data = await response.json();
            if(!data.error){
                companyName = data.name;
            }else{
                companyName = 'Unknown';
            }
        }

        // Copied Product Form Validation
        if(copiedProduct){
            // Part Number Validation
            if(state.partnum === original.partnum){
                refPartNumError.current.innerHTML = 'Part number must be different on a copied product.';
                refPartNum.current.classList.add('error-outline');
                refPartNumError.current.style.display='block';
                shouldSubmit = false;
            }

            // VeraCore Part Number Validation
            if(user.role === 'admin' || user.role === 'sales'){
                if(state.vc_product_id != '' && state.vc_product_id === original.vc_product_id){
                    refVeraCoreError.current.innerHTML = 'VeraCore Product ID must be different on a copied product.'    
                    refVeraCore.current.classList.add('error-outline');
                    refVeraCoreError.current.style.display='block';
                    shouldSubmit = false;
                      
                }
            }

            // Name Validation
            if(state.name === original.name){
                refNameError.current.innerHTML = 'Title must be different on a copied product.'
                refName.current.classList.add('error-outline');
                refNameError.current.style.display='block';
                shouldSubmit = false;
            }

            //shouldSubmit = false;
        }

        // Group Request Code
        if(shouldSubmit && confirm === null){
            confirmOverlay();
            return;
        }

        // Passed all form validation, submit to Teams
        // Group Request Version
        if(shouldSubmit && confirm != null){
            // Show overlay
            showLoading();
            let response;
            let data;
            
            // Create request object with the default fields
            const requestObj = {
                action: state.action,
                request_status: "Received",
                partnum: state.partnum,
                name: state.name.trim(),
                effective_date: state.effective_date,
                submitter_email: user.username,
                group_request: ""
            }

            if(state.image) requestObj.image = state.image
            if(refLahlouh.current){
                if(refLahlouh.current.checked){
                    //requestObj.supplied_image = "yes"
                    //requestObj.image = "";
                    requestObj.image = "lahlouh";
                } 
            }
            if(refNoImage.current){
                if(refNoImage.current.checked){
                    //requestObj.image = "";
                    requestObj.image = "none";
                } 
            }
            
            //if(state.short_desc) requestObj.short_desc = state.short_desc
            if(state.description) requestObj.description = state.description
            if(state.uom) requestObj.uom = state.uom
            if(state.orderable_qty) requestObj.orderable_qty = state.orderable_qty
            if(state.re_order_point) requestObj.re_order_point = state.re_order_point
            if(state.category) requestObj.category = state.category
            if(state.user_groups) requestObj.user_groups = state.user_groups
            if(state.arrival_date) requestObj.arrival_date = state.arrival_date
            if(state.expected_qty) requestObj.expected_qty = state.expected_qty
            if(state.batch_freq) requestObj.batch_freq = state.batch_freq
            if(state.item_type) requestObj.item_type = state.item_type
            if(state.approval_required) requestObj.approval_required = state.approval_required
            if(state.append_partnum) requestObj.append_partnum = state.append_partnum
            if(state.display_price) requestObj.display_price = state.display_price
            if(state.credit_price) requestObj.credit_price = state.credit_price
            if(state.international) requestObj.international = state.international
            if(refNextDay.current){
                if(refNextDay.current.checked && nextDay) requestObj.next_day = "yes";
            }
            if(refHoldDate.current){if(refHoldDate.current.checked) requestObj.hold_date = "yes";}
            if(state.client_notes) requestObj.client_notes = state.client_notes
            if(state.vc_product_id) requestObj.vc_product_id = state.vc_product_id
            if(state.owner) requestObj.owner = state.owner
            if(state.job_number) requestObj.job_number = state.job_number
            if(state.estimate_number) requestObj.estimate_number = state.estimate_number
            if(state.customer_number){
                requestObj.customer_number = state.customer_number
            }else{
                requestObj.customer_number = user.customer_number
            }

            // Group Request Code
            if(confirm === true || pending) requestObj.request_status = 'Pending'
            
            // Submit Image
            
            if(refAttach.current.checked && refImage.current && refImage.current.files[0]){
                let imageFilename = null
                let formData = new FormData()
                let requestOptions;

                for(let a=0;a<refImage.current.files.length;a++){
                    formData = new FormData();
                    formData.append('image', refImage.current.files[a])
                    requestOptions = {
                        method: 'POST',
                        credentials: 'include',
                        redirect: 'manual',
                        body: formData,
                    };
                    
                    response = await fetch('https://test-picform.lahlouhonline.com/api/image', requestOptions)
                    
                    if(response.status === 403){
                        hideLoading();
                        infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
                        return false;
                    }else{
                        data = await response.json()
                        if(data.filename){
                            if(a===0){
                                imageFilename = data.filename
                            }else{
                                imageFilename += ',' + data.filename;
                            }
                        }
                    }
                }
                requestObj.image = imageFilename;
            }

            if(refAttach.current.checked && copiedImage != ''){
                requestObj.image = copiedImage;
            }

            // Submit to PICDB
            let requestOptions = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestObj)
            };
            
            response = await fetch('https://test-picform.lahlouhonline.com/api/requests', requestOptions)
            
            if(response.status === 403){
                hideLoading();
                infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
            }else{
                data = await response.json()
                if(data.name){
                    // Teams Message
                    const teamsObj =
                    {
                        "@context": "https://schema.org/extensions",
                        "@type": "MessageCard",
                        "themeColor": "0076D7",
                        "summary": "Summary",
                        "sections": [
                            {
                                "activityTitle": "Portal Item Change Request",
                                "facts": [
                                    {
                                        "name": "Type:",
                                        "value": "New request"
                                    },
                                    {
                                        "name": "Action:",
                                        "value": state.action
                                    },
                                    {
                                        "name": "Company:",
                                        "value": companyName
                                    },
                                    {
                                        "name": "Submitted By:",
                                        "value": requestObj.submitter_email
                                    },
                                    {
                                        "name": "Role:",
                                        "value": user.role
                                    },
                                    {
                                        "name": "Effective Date:",
                                        "value": formatDatePicker(new Date(state.effective_date))
                                    }
                                ],
                                "text": "https://picform.lahlouhonline.com/request?id=" + data.id
                            }
                        ]
                    }

                    // Send to Teams
                    requestOptions = {
                        method: 'POST',
                        //credentials: 'include',
                        mode: 'no-cors',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(teamsObj)
                    };
                
                    // Send Teams Message
                    // Not for external clients / test portal / pending
                    if(user.role !== 'external' && companyName.indexOf('Test Portal') === -1 && requestObj.request_status !== "Pending"){
                        fetch('https://lahlouhinc.webhook.office.com/webhookb2/a51eee01-4ded-4410-ae26-8ecd3f547d46@55d9408b-9060-4745-9c50-44dba74d1b39/IncomingWebhook/c7cb6c6f43bc44a69ce761f64df16b3b/b11fe407-83f0-4667-96ae-7740b0ea7b21', requestOptions)
                    }
                    
                    hideLoading();
                }
            }
        }

        if(shouldSubmit){
            if(confirm){
                navigate('/')
                return;
            } 
            if(pending){
                infoModal("Pending Requests", "You have pending requests. Redirecting to the 'Requests' page.")    
                setTimeout(() => {
                    closePopup();
                    navigate('/requests')
                    return;
                }, 2850);
            }else{
                closePopup();
                navigate('/thankyou', { state: { type: 'request' } });
                return;
            }
        }else{
            //if(refErrors.current) refErrors.current.style.display = 'block';
            if(refErrors.current) refErrors.current.classList.add('show');
        }
    }

    // Informational Modal
    function infoModal(heading, content, duration = null){
        // Duration is in ms
        modalHeading.current.innerHTML = heading;
        modalContent.current.innerHTML = content;
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        overlay.current.className = 'show';
        popup.current.className = 'show';
        if(duration && duration > 0){
           setTimeout(() => {
             document.body.style.overflow = "auto";
             document.body.style.marginRight = '0';
             overlay.current.className = '';
             popup.current.className = '';
           }, duration)
        }
    }

    // Modal functionality
    function showModal(e){
        e.preventDefault();
        modalHeading.current.innerHTML = e.target.innerHTML;
        modalContent.current.innerHTML = e.target.title;
        
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';

        overlay.current.className = 'show';
        popup.current.className = 'show';
    }

    function showLoading(){
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        if(loadingOverlay.current) loadingOverlay.current.className = 'show';
    }

    function hideLoading(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(loadingOverlay.current) loadingOverlay.current.className = '';
    }

    function closePopup(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(overlay.current) overlay.current.className = '';
        if(popup.current) popup.current.className = '';
        if(popupConfirm.current) popupConfirm.current.className = '';
        if(refPreview.current) refPreview.current.classList.remove("show");
    }
    
    // Close popup by clicking anywhere outside of it
    window.onclick = function(event) {
        if (event.target.id === 'overlay') {
            document.body.style.overflow = "auto";
            document.body.style.marginRight = '0';
            if(overlay.current) overlay.current.className = '';
            if(popup.current) popup.current.className = '';
            if(popupConfirm.current) popupConfirm.current.className = '';
            if(refPreview.current) refPreview.current.classList.remove("show");
        }
    }

    // Back to top scroll function
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "block";
            }
        } else {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "none";
            }
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    function addBusinessDays(date, add){
        for (let i = 1; i <= add; i++) {
            date.setDate(date.getDate() + 1);
            if (date.getDay() === 6) {
                date.setDate(date.getDate() + 2);
            }
            else if (date.getDay() === 0) {
                date.setDate(date.getDate() + 1);
            }
        }

        return date;
    }

    function setDropdown(e){
        let value = e.target.parentNode.getAttribute('value')
        const items = refPortal.current.options;

        for(let a=0;a<items.length;a++){
            if(items[a].value === value){
                items[a].selected = true;
                setState(prevState => ({
                    ...prevState,
                    ['customer_number']: value
                }));
                break;
            }
        }
    }

    useEffect(() => {
        // Verify JWT Token
        const verifyUser = async () => {
            const requestOptions = {
                method: 'POST',
                credentials: 'include',
            };
      
            let response = await fetch('https://test-picform.lahlouhonline.com/api/verify', requestOptions);
            let data = await response.json()
            if(data.username){
                let todaysDate = new Date();
                todaysDate.setHours(0,0,0,0);
                const newDate = new Date(addBusinessDays(todaysDate, 1)).toISOString().split('T')[0];
                // Datepicker - 1 business day in the future, gray out past days
                refDate.current.setAttribute('min', newDate);
                refArrivalDate.current.setAttribute('min', newDate);

                let requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                };

                let response = await fetch('https://test-picform.lahlouhonline.com/api/clients', requestOptions);
                let data = await response.json()

                // Pull in Portal List
                if(data){
                    // Build Portal Select Dropdown
                    let option;
                    for(let a=0;a<data.length;a++){
                        option = document.createElement("option");
                        option.text = data[a].name;
                        option.value = data[a].customer_number;
                        refPortal.current.add(option);
                    }

                    // Build 'My Portals' list
                    if(refPortalList.current){
                        let li, anchor;
                        let split = user.customer_number.split(',');
                        for(let a=0;a<split.length;a++){
                            for(let b=0;b<data.length;b++){
                                if(split[a] == data[b].customer_number){
                                    li = document.createElement('li');
                                    li.value = data[b].customer_number;
                                    anchor = document.createElement('a');
                                    anchor.setAttribute('title','Set to ' + data[b].name);
                                    anchor.onclick = setDropdown;
                                    anchor.appendChild(document.createTextNode(data[b].name))
                                    li.appendChild(anchor);
                                    refPortalList.current.appendChild(li);
                                    break;
                                }
                            }
                        }
                    }
                }
                
                // Check to see if logged in user has any pending requests
                response = await fetch('https://test-picform.lahlouhonline.com/api/requests', requestOptions)
                data = await response.json();
                if(data){
                    // Filter to only pending requests for current user
                    let myPending = data.filter(item => item.request_status === 'Pending' && item.submitter_email === user.username);
                    if(myPending && myPending.length >0){
                        //myPending.reverse();
                        setPending(true)
                        await setPendingRequests(myPending)
                    }
                }
            }else{
              await setUser(null)
            }
        }
        
        verifyUser();
        
    }, [])

    useEffect(() => {
        if(confirm === true || confirm === false) handleSubmit()
    }, [confirm]);

    async function copyRequest(e){
        //const request = await pendingRequests.filter(request => request.id === parseInt(e.target.getAttribute('data-id')))[0]
        if(e.target.value.indexOf('to copy') !== -1) return false;

        const request = await pendingRequests.filter(request => request.id === parseInt(e.target.value))[0]
        
        let obj = {
            action: request.action,
            //supplied_image:'',
            partnum: request.partnum,
            append_partnum: request.append_partnum,
            name: request.name,
            //short_desc: '',
            description:'',
            display_price: '',
            uom:'',
            orderable_qty:'',
            re_order_point:'',
            user_groups:'',
            category:'',
            arrival_date:'',
            expected_qty:'',
            batch_freq:'',
            item_type: request.item_type,
            approval_required: request.approval_required,
            international: request.international,
            effective_date: new Date(request.effective_date).toISOString().split('T')[0],
            next_day: '',
            client_notes:'',
            vc_product_id:'',
            owner:'',
            job_number:'',
            estimate_number:'',
            credit_price:'',
            customer_number: request.customer_number,
        }
        
        // Set defaults
        refImageDetails.current.innerHTML = '';
        setCopiedImage('');
        refImage.current.value = '';

        //if(request.image != '' && request.image != null){
        if(request.image !== 'lahlouh' && request.image !== 'none'){
            obj.image = "attach"
            setCopiedImage(request.image);
            let split = request.image.split(',');
            let str = split.length + ' image';
            if(split.length > 1) str += 's';
            str += ' (from copied product)'
            refImageDetails.current.innerHTML = str;
        }

        // if(request.image === '' || request.image === null){
        //     obj.image = "none";
        // }
        
        // if(request.supplied_image != '' && request.supplied_image != null){
        //     obj.image = "lahlouh";
        //     obj.supplied_image = request.supplied_image;
        // } 

        // if(request.short_desc != '' && request.short_desc != null) obj.short_desc = request.short_desc;
        if(request.description != '' && request.description != null) obj.description = request.description;
        if(request.display_price != '' && request.display_price != null) obj.display_price = request.display_price;
        if(request.uom != '' && request.uom != null) obj.uom = request.uom;
        if(request.orderable_qty != '' && request.orderable_qty != null) obj.orderable_qty = request.orderable_qty;
        if(request.re_order_point != '' && request.re_order_point != null) obj.re_order_point = request.re_order_point;
        if(request.user_groups != '' && request.user_groups != null) obj.user_groups = request.user_groups;
        if(request.category != '' && request.category != null) obj.category = request.category;
        if(request.arrival_date != '' && request.arrival_date != null) obj.arrival_date = new Date(request.arrival_date).toISOString().split('T')[0];
        if(request.expected_qty != '' && request.expected_qty != null) obj.expected_qty = request.expected_qty;
        if(request.batch_freq != '' && request.batch_freq != null) obj.batch_freq = request.batch_freq;
        if(request.next_day != '' && request.next_day != null) obj.next_day = request.next_day;
        if(request.client_notes != '' && request.client_notes != null) obj.client_notes = request.client_notes;
        if(request.vc_product_id != '' && request.vc_product_id != null) obj.vc_product_id = request.vc_product_id;
        if(request.owner != '' && request.owner != null) obj.owner = request.owner;
        if(request.job_number != '' && request.job_number != null) obj.job_number = request.job_number;
        if(request.estimate_number != '' && request.estimate_number != null) obj.estimate_number = request.estimate_number;
        if(request.credit_price != '' && request.credit_price != null) obj.credit_price = request.credit_price;

        // Highlight all fields
        let inputs = document.getElementsByTagName('input');
        let selects = document.getElementsByTagName('select');
        for(let a=0;a<inputs.length;a++){
            inputs[a].classList.add('copy-shadow')
        }
        // Highlight all selects except the product copy dropdown
        for(let a=1;a<selects.length;a++){
            selects[a].classList.add('copy-shadow');
        }

        setCopiedProduct(true);
        setOriginal(request)

        setState(prevState => ({
            ...prevState,
            ...obj
        }));

        // Remove highlight from fields after 1s
        setTimeout(() => {
            for(let a=0;a<inputs.length;a++){
                inputs[a].classList.remove('copy-shadow')
            }
            for(let a=0;a<selects.length;a++){
                selects[a].classList.remove('copy-shadow');
            }
            //refPartNum.current.classList.add('copy-shadow')
        }, 650)

    }

    function updateImageDetails(){
        if(refImage.current.files[0]){
            let str = refImage.current.files.length;
            str += ' image';
            if(refImage.current.files.length > 1) str += 's';
            refImageDetails.current.innerHTML = str + ' (from upload)';
            setCopiedImage('');

            // Set Live Preview Image
            let reader = new FileReader();
            reader.onload = function(e) {
                refLiveImage.current.setAttribute('src', e.target.result);
            }
            reader.readAsDataURL(refImage.current.files[0]);
            // End of Set Live Preview Image

            setState(prevState => ({
                ...prevState,
                image: "attach"
            }));
        }
    }

    function switchView(str){
        if(str === 'catalog'){
            refProduct.current.classList.remove("show")
            linkProduct.current.classList.remove("active");
            refCatalog.current.classList.add("show")
            linkCatalog.current.classList.add("active")
        }else{
            refCatalog.current.classList.remove("show")
            linkCatalog.current.classList.remove("active")
            refProduct.current.classList.add("show")
            linkProduct.current.classList.add("active");
        }
    }

    if(!user) return null
    
    return (
    <>
    <div className="main">
        <div className="main-content">
            <h1 className="mt10">Add/Change Item</h1>

            <div className="mt20 center">
                Hover or click on a heading to see additional information when available.
            </div>

            <div className="mt10 center">
                Required information is marked with an asterisk (<span className="required"></span>)
            </div>

            <div className="mt10 center">
                View our <a href="/receiving-standards" target="_blank" title="Click to view our Receiving Standards">Receiving Standards</a>
            </div>

            <div className="form-header slate-bg mt20"></div>

            <div className={user.role === 'admin' || user.role === 'sales' ? "form-top" : "form-bottom"}>
            
                {/* Quick Copy */}
                {pending && pendingRequests && pendingRequests.length > 0 && pendingRequests.filter(item => item.action.indexOf('Add') !== -1 || item.action.indexOf('Change') !== -1).length > 0 && 
                <>
                <div className="element">
                    <div className="center mt5">
                        <h3>Quick Copy<span className="gg-copy"></span></h3>
                        <select
                            ref={refSelectCopy}
                            className="mt10"
                            onChange={copyRequest}
                        >
                            <option>Select a request to copy...</option>
                            {pending && pendingRequests && pendingRequests.length > 0 && pendingRequests.map((item, index) => {
                                if(pendingRequests[index].action.indexOf('Add') !== -1 || pendingRequests[index].action.indexOf('Change') !== -1){
                                    return (
                                    <option key={item.id} value={item.id}>
                                        {item.partnum} - {item.name}
                                    </option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                </div>

                <div className="thin-divider"></div>
                </>
                }

                {/* {pending && pendingRequests && pendingRequests.length > 0 && pendingRequests.map((item, index) => {
                if(index < 3){
                    return (
                    <div key={item.id} data-id={item.id} className="copy-item" onClick={copyRequest} title="Copy request details">
                        {item.partnum} - {item.name}
                        <span className="gg-copy"></span>
                    </div>
                    )
                }})} */}

                {/* Action */}
                <div className="element">
                    <label className="required">Action</label>
                    <select 
                        name="action"
                        ref={refAction}
                        value={state.action} 
                        onChange={handleChange}
                    >
                        <option>Select an option...</option>
                        <option>Add Item</option>
                        <option>Change Item</option>
                    </select>

                    <div ref={refActionError} className="errorMessage">
                        Please select an action.
                    </div>
                </div>

                {/* Product Image(s) */}
                {/* This image will be displayed on the portal. Standard default is to display front page or front panel of final image, unless otherwise specified. No PDF format */}
                <div className="small-width">
                    <label title="Any images that will be displayed on the portal. Standard default is to display the front page or front panel of the final image. For specific ordering, please name your files accordingly. No PDF format." onClick={event => showModal(event)} className="required">Product Images (Up to 5)</label>
                    
                    {/* Attach file */}
                    <label className="radio-container flex">
                        <input 
                            type="radio"
                            name="image"
                            id="image-attach"
                            //checked={state.image === 'attach'}
                            checked={state.image !== 'newimages' && state.image !== 'lahlouh' && state.image !== 'none'}
                            value="attach"
                            onChange={handleChange}
                            ref={refAttach}
                        />
                        <span className="checkmark"></span>
                        <div 
                            className="file-button"
                            onClick={() => refImage.current.click()}
                            title="Attach file(s)"
                        ><img src="./assets/images/upload2.png" />&nbsp;Choose Files</div>
                        <span ref={refImageDetails}></span>
                        <input 
                            name="attached-image"
                            ref={refImage}
                            className="mt5 hide"
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            multiple={true}
                            onChange={updateImageDetails}
                        />
                    </label>

                    {/* <div class="upload-thumbnail">
                        <table>
                            {/* <thead>
                                <th>Order</th>
                                <th>Thumbnail</th>
                                <th></th>
                            </thead> */}
                            {/* <tbody>
                                <tr>
                                    <td>&lt; &gt;</td>
                                    <td>1</td>
                                    <td><img ref={refImageOne} height="35" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                </div>

                {/* Lahlouh Supplied Image */}
                <div className="small-width">
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="image"
                            id="image-lahlouh"
                            checked={state.image === 'lahlouh'}
                            value="lahlouh"
                            onChange={handleChange}
                            ref={refLahlouh}
                        />
                        <span className="checkmark"></span>
                        Lahlouh Supplied Image
                    </label>
                </div>

                {/* No Image Needed */}
                <div className="small-width mt18">
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="image"
                            id="image-none"
                            checked={state.image === 'none'}
                            value="none"
                            onChange={handleChange}
                            ref={refNoImage}
                        />
                        <span className="checkmark"></span>
                        No Image Needed
                    </label>
                </div>

                <div ref={refImageError} className="small-width errorMessage"></div>

                {/* Part Number */}
                <div className="element">
                    <label className="required" title="Enter the client part number, which usually matches the Veracore Product ID." onClick={event => showModal(event)}>Client Part No. (SKU)</label>
                    <input 
                        name="partnum"
                        ref={refPartNum}
                        type="text"
                        value={state.partnum}
                        onChange={handleChange}
                    />

                    <div ref={refPartNumError} className="errorMessage"></div>
                </div>

                {/* Display Client Part No. (SKU)? */}
                <div className="radio-group">
                    <label title="Select 'Yes' to append the Client Part No. (SKU) onto the end of the product title on the site." onClick={event => showModal(event)}>Display Client Part No. (SKU)?</label>
                    
                    {/* Append Part Number = Yes */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="append_partnum"
                            id="append-partnum-yes"
                            checked={state.append_partnum === 'yes'}
                            value="yes"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Yes
                    </label>

                    {/* Append Part Number = No */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="append_partnum"
                            id="append-partnum-no"
                            checked={state.append_partnum === 'no'}
                            value="no"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        No
                    </label>
                </div>
                
                {/* Product Title */}
                <div className="element">
                    <label className="required" title="Enter the product title you would like displayed on the portal." onClick={event => showModal(event)}>Product Title</label>
                    <input 
                        name="name"
                        ref={refName}
                        type="text"
                        min="2022-11-06"
                        value={state.name}
                        onChange={handleChange}
                    />
                    <div ref={refNameError} className="errorMessage">
                        Please enter a product title.
                    </div>
                </div>

                {/* Short Description */}
                {/* <div className="element">
                    <label title="This is an optional brief description that will show up on the catalog page." onClick={event => showModal(event)}>
                        Product Short Description (Optional)
                    </label>
                    <div className="textarea-wrapper">
                        <textarea
                            name="short_desc"
                            className="short-desc"
                            value={state.short_desc}
                            onChange={handleChange}
                            maxlength={165}
                        />
                    </div>
                </div> */}

                {/* Long Description */}
                <div className="element">
                    <label title="This is an optional description that will show up on the product details page." onClick={event => showModal(event)}>
                        Product Description (Optional)
                    </label>
                    <div className="textarea-wrapper">
                        <textarea
                            name="description"
                            value={state.description}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                {/* Display Price */}
                <div className="element">
                    <label title="This is an optional field to confirm the product price displayed to the end user." onClick={event => showModal(event)}>
                        Display Price (Optional)
                    </label>
                    <input 
                        name="display_price"
                        ref={refDisplayPrice}
                        type="text"
                        value={state.display_price}
                        onChange={handleChange}
                    />
                    <div ref={refDisplayPriceError} className="errorMessage">
                        
                    </div>
                </div>

                {/* Unit of Measure */}
                <div className="element">
                    <label className={state.action.indexOf('Add') !== -1 ? "required" : ""} title="Unit of Measure (UOM) refers to the quantity that the item is warehoused in, i.e., each, packs of 5, packs of 10, etc." onClick={event => showModal(event)}>Unit of Measure (UOM)</label>
                    <input
                      type="text"
                      name="uom"
                      ref={refUOM}
                      value={state.uom}
                      onChange={handleChange}
                    />
                    <div ref={refUOMError} className="errorMessage">
                        Please enter a unit of measure.
                    </div>
                </div>

                {/* Orderable Quantities */}
                <div className="element">
                    <label className={state.action.indexOf('Add') !== -1 ? "required" : ""} title="Indicate all orderable quantities that should be displayed in the portal drop-down fields. Make sure that your quantities are consistent with your unit of measure." onClick={event => showModal(event)}>Orderable Quantities (by UOM)</label>
                    <input
                        type="text"
                        name="orderable_qty"
                        ref={refOrderQty}
                        value={state.orderable_qty}
                        onChange={handleChange}
                    />
                    <div ref={refOrderQtyError} className="errorMessage">
                        Please enter any orderable quantities.
                    </div>
                </div>

                {/* Re-Order Point */}
                <div className="element">
                    <label title="Indicate the quantity on hand at which you want to receive an automated re-order reminder email. Keep in mind the time it takes to produce the reprint." onClick={event => showModal(event)}>
                    Re-Order Point (by UOM)</label>
                    <input
                        type="text"
                        name="re_order_point"
                        value={state.re_order_point}
                        onChange={handleChange}
                    />
                </div>

                {/* Division / Business Unit / User Group */}
                <div className="element">
                    <label className={state.action.indexOf('Add') !== -1 ? "required" : ""} title="This refers to which division, business unit, or user group(s) have visibility to the item on the portal.  Include all groups if more than one." onClick={event => showModal(event)}>
                        Division / Business Unit / User Group
                    </label>
                    <input
                        type="text"
                        name="user_groups"
                        ref={refUserGroup}
                        value={state.user_groups}
                        onChange={handleChange}
                    />
                    <div ref={refUserGroupError} className="errorMessage">
                        Please enter any associated user groups.
                    </div>
                </div>

                {/* Catalog / Category / Subcategory */}
                <div className="element">
                    <label className={state.action.indexOf('Add') !== -1 ? "required" : ""} title="This refers to where the product is placed on the portal. Common groupings include: Brand, Product Type, etc." onClick={event => showModal(event)}>
                        Catalog / Category / Subcategory
                    </label>
                    <input
                        type="text"
                        name="category"
                        ref={refCategory}
                        value={state.category}
                        onChange={handleChange}
                    />
                    <div ref={refCategoryError} className="errorMessage">
                        Please enter the product category.
                    </div>
                </div>

                {/* Arrival Date */}
                <div className="element">
                    <label className={state.action.indexOf('Add') !== -1 ? "required" : ""} title="This is the date the items are scheduled to arrive at the Lahlouh warehouse." onClick={event => showModal(event)}>Expected Arrival Date</label>
                    <input
                          ref={refArrivalDate}
                          type="date"
                          name="arrival_date"
                          value={state.arrival_date}
                          onChange={handleChange}
                    />
                    <div ref={refArrivalDateError} className="errorMessage">
                        Please enter the expected arrival date.
                    </div>
                </div>

                {/* Expected Quantity */}
                <div className="element">
                    <label className={state.action.indexOf('Add') !== -1 ? "required" : ""} title="Please provide expected quantity if product will be shipping to Lahlouh's warehouse." onClick={event => showModal(event)}>Expected Quantity</label>
                    <input
                      type="text"
                      name="expected_qty"
                      ref={refExpectedQty}
                      value={state.expected_qty}
                      onChange={handleChange}
                    />
                    <div ref={refExpectedQtyError} className="errorMessage">
                        Please enter the expected quantity.
                    </div>
                </div>

                {/* Batch Frequency */}
                <div className="element">
                    <label title="If item will be printed on demand (POD), please indicate if you prefer items to be printed in batches and if so, at what frequency." onClick={event => showModal(event)}>
                        Batch Frequency (POD Items)
                    </label> 
                    <select 
                        name="batch_freq"
                        ref={refBatchFreq}
                        value={state.batch_freq} 
                        onChange={handleChange}
                    >
                        <option value=''>Select an option...</option>
                        <option>No Batching</option>
                        <option>Every Monday</option>
                        <option>Every Tuesday</option>
                        <option>Every Wednesday</option>
                        <option>Every Thursday</option>
                        <option>Every Friday</option>
                        <option>Other</option>
                    </select>
                </div>
               
                {/* Item Type */}
                <div className="radio-group">
                <label title="Choose the type of item to be added, being mindful of any listed restrictions." onClick={event => showModal(event)}>Item Type</label>
                    {/* Food */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-food"
                            checked={state.item_type === 'food'}
                            value="food"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Food Items</strong> - Cannot ship internationally. Please provide expiration dates and lot numbers in special instructions.
                    </label>

                    {/* Agricultural or Sanitizer */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-ag-or-sanitizer"
                            checked={state.item_type === 'agricultural-or-sanitizer'}
                            value="agricultural-or-sanitizer"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Agricultural Items & Sanitizer</strong> - Cannot ship internationally.
                    </label>

                    {/* Contains Batteries */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-contains-batteries"
                            checked={state.item_type === 'contains-batteries'}
                            value="contains-batteries"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Items with Batteries</strong> - Subject to shipping restrictions and special labeling requirements.
                    </label>

                    {/* Face Mask */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-face-mask"
                            checked={state.item_type === 'face-mask'}
                            value="face-mask"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Face Masks</strong> - Subject to international shipping restrictions by country.
                    </label>

                    {/* Other */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-other"
                            checked={state.item_type === 'other'}
                            value="other"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Other</strong>
                    </label>
                </div>

                <div className="thin-divider"></div>

                {/* Approvals Required? */}
                <div className="radio-group">
                    <label title="Indicate if an approval is required when this item is ordered.  Add instructions in the product notes field if needed." onClick={event => showModal(event)}>
                        Order Approvals Required?
                    </label>
                    
                    {/* Approval Required = Yes */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="approval_required"
                            id="approval-yes"
                            checked={state.approval_required === 'yes'}
                            value="yes"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Yes
                    </label>

                    {/* Approval Required = No */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="approval_required"
                            id="approval-no"
                            checked={state.approval_required === 'no'}
                            value="no"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        No
                    </label>
                </div>

                <div className="thin-divider"></div>

                {/* International Shipping? */}
                <div className="radio-group">
                    <label title="If international shipping is needed please include item value ($) & country of origin in the comments field." onClick={event => showModal(event)}>International Shipping?</label>
                    
                    {/* International Shipping = Yes */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="international"
                            id="international-yes"
                            checked={state.international === 'yes'}
                            value="yes"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Yes
                    </label>

                    {/* International Shipping = No */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="international"
                            id="international-no"
                            checked={state.international === 'no'}
                            value="no"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        No
                    </label>
                </div>

                {/* Effective Date */}
                <div className="element">
                    <label className="required" title="Change could go live sooner than this date depending on the teams workload. Check the 'Hold until effective date' checkbox if needed." onClick={event => showModal(event)}>Effective Date</label>
                    <input 
                        type="date"
                        name="effective_date"
                        ref={refDate}
                        value={state.effective_date}
                        onChange={handleChange}
                    />

                    <div ref={refDateError} className="errorMessage"></div>

                    <div ref={refDateCharge} className="radio-check-wrapper errorMessage">
                      <label className="checkbox-container no-highlight mt5">
                            <input 
                                ref={refNextDay}
                                type="checkbox"
                                name="next-day-check"
                                id="next-day"
                                value={state.next_day}
                                onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                            Next day service may incur an additional charge.
                        </label>
                    </div>
                </div>

                {/* Hold Date */}
                <div className="element">
                    <div className="radio-check-wrapper padLeft8">
                      <label className="checkbox-container no-highlight">
                            <input 
                                ref={refHoldDate}
                                type="checkbox"
                                name="hold-date-check"
                                id="hold-date"
                                //value="yes"
                                //value={state.hold_date}
                                //checked={state.hold_date === 'yes'}
                                onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                            Hold until effective date
                        </label>
                    </div>
                </div>

                {/* Product Notes */}
                <div className="element">
                    <label className={state.action.indexOf('Change') !== -1 ? "required" : ""} title="Enter any specific actions or changes related to this product. Description, placement on the site, etc. Include any details that are needed to help aid the actions to be taken." onClick={event => showModal(event)}>
                        Additional Product Notes
                    </label>
                    <div className="textarea-wrapper">
                        <textarea
                        name="client_notes"
                        ref={refNotes}
                        value={state.client_notes}
                        onChange={handleChange}
                        />
                    </div>
                    <div ref={refNotesError} className="errorMessage">
                        Please enter any needed change notes.
                    </div>
                </div>
            </div>

            {(user.role === 'admin' || user.role === 'sales') &&
            <div className="internal-content">
              <div className="form-divider yellow-bg center">
                Lahlouh Internal
              </div>

              {/* <h2 className="center">Lahlouh Details</h2> */}
              
              <div className="form-bottom">
                {/* VeraCore Product ID */}
                <div className="element">
                    <label className="required" title="This is the product ID that is assigned by Lahlouh. Ideally, it is the same as the Client Part No. (SKU) and should be included on the physical inventory item. It is included on all carton labels." onClick={event => showModal(event)}>
                        VeraCore Product ID #
                    </label> 
                    <input
                      type="text"
                      ref={refVeraCore}
                      name="vc_product_id"
                      value={state.vc_product_id}
                      onChange={handleChange}
                    />

                    <div ref={refVeraCoreError} className="errorMessage"></div>
                </div>

                {/* Owner */}
                <div className="element">
                    <label title="Enter the VeraCore owner info for this particular product." onClick={event => showModal(event)}>
                        Owner
                    </label> 
                    <input
                      type="text"
                      ref={refOwner}
                      name="owner"
                      value={state.owner}
                      onChange={handleChange}
                    />

                    <div ref={refOwnerError} className="errorMessage">
                        Enter the VeraCore owner.
                    </div>
                </div>
                
                {/* Job Number */}
                {state.action !== 'Remove Item' && state.action !== 'Image Update' &&
                <div className="element">
                    <label title="Only required if product is in production at the time this form is submitted." onClick={event => showModal(event)}>
                        Lahlouh Job #
                    </label> 
                    <input
                      type="text"
                      name="job_number"
                      value={state.job_number}
                      onChange={handleChange}
                    />
                </div>
                }

                {/* PACE Template # */}
                {state.action !== 'Remove Item' && state.action !== 'Image Update' &&
                <div className="element">
                    <label className={refBatchFreq.current && !refBatchFreq.current.options[0].selected && !refBatchFreq.current.options[1].selected ? "required" : ""} title="Only required with print-on-demand (POD) items." onClick={event => showModal(event)}>
                      PACE Template # (for POD Items)
                    </label> 
                    <input
                      type="text"
                      name="estimate_number"
                      ref={refEstimate}
                      value={state.estimate_number}
                      onChange={handleChange}
                    />
                    <div ref={refEstimateError} className="errorMessage">
                        Please enter a PACE Template #.
                    </div>
                </div>
                }

                {/* Credit / Reimbursement Price */}
                {state.action !== 'Remove Item' && state.action !== 'Image Update' &&
                <div className="element">
                    <label title="Cost of product that will need to be reimbursed or credit given per unit." onClick={event => showModal(event)}>
                      Reimbursement or Credit Price
                    </label> 
                    <input
                      type="text"
                      name="credit_price"
                      ref={refCreditPrice}
                      value={state.credit_price}
                      onChange={handleChange}
                    />
                    <div ref={refCreditPriceError} className="errorMessage"></div>
                </div>
                }
                
                {/* Portal Selection */}
                <div className="element">
                    <label className="required">Portal Selection</label>

                    {user.customer_number.split(',')[0] !== '1' && user.role === 'sales' &&
                    <div className="portal-list-wrapper padLeft8">
                        <strong>My Portals</strong>
                        <ul ref={refPortalList} className="portal-list mt5">
                        </ul>
                    </div>
                    }

                    <select 
                        name="customer_number"
                        ref={refPortal}
                        value={state.customer_number} 
                        onChange={handleChange}
                    >
                        <option>Select an option...</option>
                    </select>

                    <div ref={refPortalError} className="errorMessage">
                        Please select a portal.
                    </div>
                </div>
              </div>
            </div>
            }

            <div ref={refErrors} className="small-width errorMaster center">
                There are items that require your attention above
            </div>
           
            <div className="submit-save-wrapper mt20 center">
                <button className="primary-button" title="Submit" onClick={handleSubmit}>Submit</button>
                {/* <button className="secondary-button" title="Save for later" onClick={handleSubmit}>Save for later</button> */}
            </div>
        </div>

        <div ref={refPreview} className="live-preview-wrapper">
            <div className="live-preview-header">
                Live Preview
            </div>

            <div className="preview-header">
                <span
                    ref={linkCatalog}
                    className='active'
                    onClick={() => switchView('catalog')}
                    >Catalog View
                </span> | 
                <span
                    ref={linkProduct}
                    onClick={() => switchView('product')}
                >
                    Product Details
                </span>
            </div>
            
            <div ref={refCatalog} className="catalog show">
                <div className="catalog-image">
                    <img ref={refLiveImage} src="assets/images/sample-image2.png" />
                </div>
                <div className="catalog-title">
                    {state.append_partnum === 'yes' && state.partnum ? (state.name + ' / ' + state.partnum).substring(0,72) : state.name.substring(0,74)}
                    {(state.name + ' / ' + state.partnum).length > 72 || state.name.length > 72 && " ..."}
                    {state.append_partnum === 'no' && state.name.length > 72 && " ..."}
                </div>

                {/* <div ref={refShortDesc} className="catalog-desc">
                    {state.short_desc &&  state.short_desc.substring(0,165)}
                    {state.description.length > 165 && " ..."}
                </div> */}

                <div className="catalog-uom">
                    {state.uom && state.uom}
                </div>

                <div className="catalog-add-to-cart">
                    <img src="assets/images/add-to-cart.png" />
                </div>
            </div>

            <div className="product-view" ref={refProduct}>
                {/* <span className="close" onClick={closePopup}>&times;</span> */}
                {/* <div className="pv-image mt10">
                    <img ref={refLiveImage} src="assets/images/sample-image2.png" />
                </div> */}
                <div className="pv-title">
                    {state.append_partnum === 'yes' && state.partnum ? (state.name + ' / ' + state.partnum) : state.name}
                    {/* {state.append_partnum === 'no' && state.name.length > 72 && " ..."} */}
                </div>

                <div className="pv-stock">
                    <img src="assets/images/in-stock.png" />
                </div>

                <div ref={refShortDesc} className="pv-desc">
                    {/* {state.description &&  state.description.substring(0,150)}
                    {state.description.length > 150 && " ..."} */}
                </div>

                <div className="pv-qty">
                    <img src="assets/images/pv-qty.png" />
                </div>

                <div className="pv-price">
                    {state.display_price && "$" + state.display_price}
                    {state.display_price && <span className="pv-currency">USD</span>}
                </div>

                {/* <div className="pv-uom">
                    {state.uom && state.uom}
                </div> */}

                {state.display_price &&
                <div className="pv-summary">
                    <div className="pv-summary-title">Summary</div>
                    <div className="pv-summary-row">
                        <div>Product Total (1 Item)</div>
                        <div>${state.display_price}</div>
                    </div>

                    <div className="pv-summary-row">
                        <div>Shipping calculated at checkout</div>
                        <div />
                    </div>

                    <div className="pv-summary-row">
                        <div>Tax</div>
                        <div>$0.00</div>
                    </div>

                    <div className="pv-summary-divider" />

                    <div className="pv-summary-row">
                        <div class="semi-bold">Total price (USD)</div>
                        <div className="pv-total">${state.display_price}</div>
                    </div>

                </div>
                }

                <div className="pv-add-to-cart">Add to Cart</div>
                
            </div>

            
        </div>
    </div>

    <div ref={refReturnTop} className="return-top" onClick={topFunction}>
      &#x25B2; Return to top
    </div>

    <div id="overlay" ref={overlay}></div>

    <div id="loading-overlay" ref={loadingOverlay}>
        <div className="deadCenter">
            <div className="lds-spinner">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
        </div>
    </div>

    <div id="popup" ref={popup}>
        <h2 id="modal-heading" className="center" ref={modalHeading}></h2>
        <span id="popup-close" ref={modalClose} className="close" onClick={closePopup}>&times;</span>
        <div id="modal-content" ref={modalContent} className="content center"></div>
    </div>

    <div id="popup-confirm" ref={popupConfirm}>
        <h2 id="modal-heading" ref={popupConfirmHeading} style={{textAlign:'center'}}>Anything Else?</h2>
        <span className="close" ref={popupConfirmClose} onClick={() => {closePopup();setConfirm(null)}}>&times;</span>
        <div id="modal-content" ref={popupConfirmContent} className="content center">
          Text changed through JS
        </div>
        <div className="mt10 center">
          <button className="primary-button smaller-button" onClick={(e) => {closePopup();overlay.current.className = 'show';setConfirm(true);}} title="Yes">Yes</button>
          <button className="secondary-button smaller-button" onClick={() => {closePopup();overlay.current.className = 'show';setConfirm(false);}} title="No">No</button>
        </div>
    </div>
    </>
  )
}
